@import "../../variables.scss";
.gridHeader {
  width: 100%;
  position: sticky;
  top: 82px;
  z-index: 8;
  background-color: black;
  height: 113px;
  display: flex;
  padding-left: 48px;
  gap: 16px;
  align-items: center;

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
  .subtitle-container {
    display: inline-flex;
    padding-top: 10px;

    .subtitle::after {
      content: "|";
      position: relative;
      font-weight: 900;
      padding: 0 8px;
    }
  }
  .gridHeaderTitle {
    display: flex;
    padding: 0px 0px 0px;
    flex-direction: row;
    align-items: center;
  }
  .gridHeaderTitle.row {
    padding: 0px 0px 0px;
    flex-direction: row;
    align-items: center;
  }
  .viewAllBack {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 112px;
    }
  }
}
.gridHeader h1 {
  font: $primary-font;
  font-size: $large-font-size;
  margin: 0;
  line-height: 32px;
  .searchTerm {
    color: $secondary-font-color;
  }
}
.gridHeader h2 {
  font: $regular-font;
  font-size: $medium-font-size;
  color: $secondary-font-color;
  white-space: nowrap;
  margin: 0px;
}

.grid {
  width: 100%;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 24px 0 0 38px;
}
.column {
  .character-card {
    border-radius: 0;
    .cast-placeholder {
      border-radius: 0;
    }
  }
}
.view-all-loading-wrapper {
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@supports (display: grid) {
  .row {
    padding: 24px 42px 0 38px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(161px, 1fr));
    grid-column-gap: 20px;
  }
  .row.landscape {
    grid-template-columns: repeat(auto-fill, minmax(324px, 1fr));
    grid-column-gap: 24px;
  }
  .row.teams {
    display: flex;
    padding-left: 210px;
    padding-right: 125px;
  }
}
.column {
  margin-bottom: 35px;
  @media only screen and (max-width: 1366px) {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1366px) {
  .gridHeader .breadcrumb .breadcrumb-segment:not(:last-child) {
    display: none; /* Hide all breadcrumb segments except the last one */
  }
  .row.teams {
    padding-left: 110px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .gridHeader {
    padding: 0 24px 0 24px;
  }
}

@media only screen and (max-width: 780px) {
  .row {
    padding: 24x 0 0 15px;
    grid-column-gap: 10px;
  }
}

.channel-placeholder {
  display: relative;
  align-items: center;
  justify-content: center;
}

.text-over-channel-placeholder {
  position: absolute;
  top: 5%;
  left: 10%;
}
