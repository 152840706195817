@import "../../variables.scss";

.rating-list-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  .rating-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0;
    border-bottom: 1px solid $schedule-bg;
    padding: 16px 32px;
    background-color: transparent;
    color: $white;
    cursor: pointer;

    &.highlighted {
      background-color: $translucent-white;
      border-bottom-color: $dark-gray;
    }

    &.allow-all-row .rating-text {
      line-height: 58px;
    }

    &[disabled] {
      pointer-events: none;
      &:not(.allow-all-row) {
        opacity: 0.5;
      }
    }

    .rating-text {
      display: flex;
      flex-direction: column;
      font: $regular-font;
      font-size: $medium-font-size;
      text-align: left;
      :last-child {
        color: $gray;
      }
    }

    .rating-icon {
      width: 32px;
      height: 32px;
    }
  }
}
