@import "../../variables.scss";
@import "../../mixins.scss";

.select-avatar-container {
  .avatar-grid {
    margin-top: 80px;
  }
  .cancel-button {
    @include profiles-floating-button;
  }
}

.edit-profile-form {
  @include profiles-form;

  .delete-text-container {
    margin-top: 60px;
    justify-content: center;
    color: $gray;
    font: $regular-font;
    font-size: $cta-btn-font-size;
  }

  .delete-button-container {
    margin-top: 40px;
    justify-content: center;
    button {
      width: auto;
      background-color: transparent;
      &:hover span {
        text-decoration: underline;
      }
    }
  }

  .button-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 24px;
    padding: 12px 16px;
    background-color: $translucent-white;
    border: 0;
    border-radius: 8px;
    font: $regular-font;
    font-size: $medium-font-size;
    line-height: 27px;
    color: $primary-font-color;
    cursor: pointer;

    &:hover {
      background-color: opacify($translucent-white, 0.1);
    }

    .label {
      font-weight: bold;
    }

    .selected-value {
      flex: 1;
      color: $secondary-font-color;
      text-align: right;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      margin-left: 8px;

      .chevron-icon {
        width: 6px;
        height: 12px;
      }
      .info-icon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .dropdown-container {
    position: relative;
    margin-top: 24px;

    .button-container {
      padding: 12px 16px;
      font-size: $medium-font-size;
      background-color: $translucent-white;
      min-height: initial;
    }
    .dropdown-button {
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      border-radius: 8px;
      line-height: 27px;
      font-weight: 600;

      .labelTag {
        font-weight: normal;
      }
    }
  }
}

.modal .modal-container .modal-wrap.delete-profile-modal {
  width: 400px;

  .modal-header h2 {
    margin: 30px 24px 24px;
    font-size: 28px;

    .profile-name.overflow {
      display: inline-block;
      vertical-align: top;
      width: 15ch;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: -3px;
    }
  }

  .modal-body {
    font-size: $medium-font-size;
    padding: 0 24px 8px;
    color: $gray;
  }

  .button {
    width: 336px;
    margin: 0;
    margin-top: 16px;

    &.delete-button {
      background-color: $dark-red;

      &:hover {
        background-color: $cinnabar-red;
      }
    }
  }
}
