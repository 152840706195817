@import "../../variables.scss";

.scrollable {
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

@media only screen and (max-width: 1366px) {
  .scrollable {
    width: 292px;
  }
}

@media only screen and (max-width: 768px) {
  .scrollable {
    width: 236px;
  }
}

/*IE*/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .scrollable {
    /* ie scrollbar color properties */
    scrollbar-face-color: $dropdown-scroll-color;
    scrollbar-track-color: $modal-primary-bg;
    scrollbar-arrow-color: $modal-primary-bg;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .scrollable:after {
    content: "";
    height: 32px;
    display: block;
  }
}

/*Edge*/
@supports (-ms-ime-align: auto) {
  .scrollable {
    margin-right: -10px;
    padding-top: 16px;
    margin-top: -16px;
    margin-bottom: -16px;
    padding-bottom: 16px;
  }

  .scrollable:after {
    content: "";
    height: 16px;
    display: block;
  }
}

/*Firefox*/
.scrollable {
  scrollbar-width: thin;
  scrollbar-color: $dropdown-scroll-color $modal-primary-bg;
}

/*Chrome*/
.scrollable::-webkit-scrollbar-track {
  width: 4px;
  margin: 8px 0;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: $dropdown-scroll-color;
  border: 1px solid transparent;
  background-clip: content-box;
  border-radius: 5px;
}

.scrollable::-webkit-scrollbar {
  width: 7px;
}

.filter-applied {
  color: $strong-green;
}
