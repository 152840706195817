@import "../../variables.scss";

.mobile-view {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.apple-store-btn {
  margin-top: 100px;
}

.apple-store-btn,
.google-store-btn {
  margin-bottom: 34px;
}

.logo-container {
  width: 255px;
  text-align: center;
}

.logo-container {
  .logo-wrapper {
    margin: 0;
  }
  .logo {
    min-width: 100%;
  }
}
