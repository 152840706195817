@import "../../variables.scss";

.basics {
  text-align: center;
  max-width: 895px;
  margin: 31px auto 10px;
}

.basics h1 {
  font: $primary-font;
  font-size: $large-font-size-settings-page;
}

.basics-row {
  display: flex;
  border-bottom: 1px solid $abbey-gray-settings;
  padding: 25px 0px;
  text-align: left;
  font: $regular-font;
  font-size: $medium-font-size;

  .basics-thumb {
    width: 361px;
    margin-right: 30px;
    img {
      width: 361px;
      border-radius: 4px;
    }
  }
  .basics-thumb-description {
    h3 {
      font-size: $regular-font;
      font-weight: 600;
      color: $white;
      margin: 0 0 8px 0;
      padding: 0;
    }
    p {
      font-size: $medium-font-size;
      color: $gray;
      margin: 0;
      padding: 0;
    }
  }
}

.basics-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .basics-label {
    font: $primary-font;
    color: $primary-font-color;
    font-size: $large-font-size-mhd;
  }
}

@media only screen and (max-width: 1366px) {
  .basics-row {
    font: $regular-font-mhd;
    padding: 25px 0px;
  }
  .basics {
    margin-top: 18px;
  }
  .basics h1 {
    font-size: $large-font-size-mhd;
  }
}

@media only screen and (max-width: 768px) {
  .basics {
    width: 550px;
    margin-top: 29px;
  }
  .basics-row {
    font: $regular-font-hd;
    padding: 22px 0px;
  }
  .basics h1 {
    font-size: $large-font-size-hd;
  }
}
