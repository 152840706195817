@import "../../variables.scss";

.recording-panel {
  width: 437px;
  position: fixed;
  height: calc(100% - #{$top-nav-height});
  top: $top-nav-height;
  padding: 40px 30px 0px;
  background-color: $cod-gray;
  overflow-y: hidden;
  z-index: 10;
  box-sizing: border-box;
}

.recording-type {
  font: $regular-font;
  color: $white;
}

.PVR-wall-button {
  width: 100%;
  height: 66px;
  margin: 0;
  padding-left: 10px;
  border-radius: 10px;
  background-color: $cod-gray;
  justify-content: flex-start;
  gap: 16px;

  & span {
    font-weight: normal;
    display: flex;
  }
  &:hover {
    background-color: $cod-gray;
  }
  &.scheduled {
    bottom: 25px;
  }

  .button-content img {
    margin-right: 10px;
  }

  &.selected {
    background-color: $schedule-bg;
    border-radius: 10px;
    gap: 16px;
  }
  &.scheduled {
    background-color: $schedule-bg;
    border-radius: 10px;
    gap: 16px;
  }
  .icon {
    width: 24px;
    height: 24px;
  }
}

.PVR-manager {
  position: absolute;
  bottom: 0px;
  padding: 0 0px 60px;
}

.PVR-space {
  width: 100%;
  height: 34px;
  font: $regular-font;
  margin: 0 0 0 12.9px;
  font-size: $medium-font-size;
}
.PVR-icon {
  width: 30px;
}
.progress-recording-bar {
  width: 100%;
  margin: 20px 0 17px 0px;
}
.recording-space {
  font: $regular-font;
  font-size: $medium-font-size;
}
.recording-play-info {
  margin-top: 20px;
  color: $gray;
}
.recording-side-panel-poster {
  background-color: $shark-gray;
  height: 212px;
  width: 100%;
}
.info-popup {
  flex-shrink: 0;
}
.recording-image {
  width: 100%;
  height: 212px;
  border-radius: 4px;
}
.series-recording-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  .recording-series-metadata {
    margin-top: 30px;
    text-align: center;
  }
  .series-recording-title {
    font: $primary-font;
  }
  .recordings-total {
    font-size: $medium-font-size;
    color: $gray;
    margin-top: 10px;
    flex-direction: row;
  }
  .panel-recording-actions {
    margin-top: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 130px;

    &.single-action {
      justify-content: space-around;
    }

    .edit-button-container {
      position: relative;
    }

    .action-icon {
      position: relative;
      width: 50px;
      height: 50px;
      background-color: $schedule-bg;
      margin: 0;
      img {
        width: 20px;
        height: 20px;
      }
      &:hover {
        background-color: $scorpion-gray;
      }
      cursor: pointer;
    }
  }
}

@media only screen and (min-width: 1920px) {
  .info-popup {
    display: none;
  }
}
@media only screen and (min-width: 1900px) and (max-width: 1920px) {
  .info-popup {
    display: none;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 1900px) {
  .info-popup {
    display: none;
  }
}
@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .PVR-wall-button {
    justify-content: center;
    margin: 0px;
    padding: 0px;
    .icon {
      width: 24px;
      height: 24px;
      margin: 0px;
    }
  }
  .info-popup {
    display: block;
  }
  .recording-panel {
    width: 130px;
    flex-shrink: 0;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .recording-space {
    display: none;
  }

  .recording-play-info {
    display: none;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .PVR-wall-button {
    justify-content: center;
    margin: 0px;
    padding: 0px;
    .icon {
      width: 24px;
      height: 24px;
      margin: 0px;
    }
  }
  .info-popup {
    display: block;
  }
  .recording-panel {
    width: 130px;
    flex-shrink: 0;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .recording-space {
    display: none;
  }

  .recording-play-info {
    display: none;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px),
  only screen and (min-width: 768px) and (max-width: 1024px),
  only screen and (min-width: 50px) and (max-width: 768px),
  only screen and (min-width: 1366px) and (max-width: 1600px) {
  img.icon.PVR-wall-button.selected,
  img.icon.PVR-wall-button.scheduled {
    width: 24px !important;
    height: 24px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0px;
    padding: 0px;
  }

  .PVR-wall-button.selected,
  .PVR-wall-button.scheduled {
    width: 66px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0px;
    padding: 0px;
  }
  .button-content img {
    margin: 0px;
  }
  .PVR-wall-button {
    justify-content: center;
    margin: 0px;
    padding: 0px;
  }

  /* Applying & span styling */
  .PVR-wall-button span {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .PVR-wall-button {
    justify-content: center;
    margin: 0px;
    padding: 0px;
    .icon {
      width: 24px;
      height: 24px;
      margin: 0px;
    }
  }
  .info-popup {
    display: block;
  }
  .recording-panel {
    width: 130px;
    flex-shrink: 0;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .recording-space {
    display: none;
  }

  .recording-play-info {
    display: none;
  }
}
@media only screen and (min-width: 50px) and (max-width: 768px) {
  .PVR-wall-button {
    justify-content: center;
    margin: 0px;
    padding: 0px;
    .icon {
      width: 24px;
      height: 24px;
      margin: 0px;
    }
  }
  .recording-panel {
    width: 130px;
    flex-shrink: 0;
    z-index: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  .recording-space {
    display: none;
  }

  .info-popup {
    display: block;
  }
  .PVR-wall-button span {
    display: none;
  }
}
