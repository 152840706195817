@import "../../variables.scss";

.parental-pin-page {
  width: 900px;
  max-width: 100%;
  margin: 64px auto;

  .rating-list-container {
    margin: 0;
    & + .parental-pin-action-container {
      margin-top: 30px;
    }
  }
}

.parental-pin-heading {
  margin: 0;
  font: $primary-font;
  color: $primary-font-color;
  font-size: $large-font-size;
}

.parental-pin-desc {
  margin-top: 50px;
  margin-bottom: 24px;
  font: $regular-font;
  color: $gray;
  font-size: $medium-font-size;
  white-space: pre-wrap;
}

.parental-pin-toggle-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 0;
  border-top: 1px solid $schedule-bg;
  border-bottom: 1px solid $schedule-bg;

  .toggle-pin-switch {
    display: flex;
  }

  .toggle-label {
    font: $primary-font;
    color: $primary-font-color;
    font-size: $medium-font-size;
  }
}

.rating-definition-button {
  margin-bottom: 24px;
  font: $regular-font;
  font-size: $medium-font-size;
  color: $primary-font-color;
  background: none;
  border: 0;
  padding: 0;
  &:hover {
    cursor: pointer;
  }
}

.parental-pin-action-container {
  margin-top: 26px;
  margin-bottom: 54px;
  > * {
    margin-right: 24px;
  }
}

.clear-button {
  border: 2px solid $dove-gray;
  padding: 16px;
  border-radius: 10px;
  background-color: $black;
  font: $primary-font;
  font-size: $medium-font-size;
  color: $primary-font-color;
  line-height: 18px;
  cursor: pointer;
  &:hover {
    background-color: $abbey-gray-settings;
  }
}

@media only screen and (max-width: 1366px) {
  .parental-pin-page {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .parental-pin-page {
    width: 550px;
    margin-top: 53px;
  }
}
