@import "../../variables.scss";

.recordings-section-container {
  margin-left: 437px;
  min-width: 420px;
}

.recordings-page-header {
  top: 82px;
  position: sticky;
  z-index: 5;
  background-color: black;
  height: 113px;
  font-size: 28px;
  display: flex;
  gap: 16px;
  margin-left: 48px;
  margin-right: 48px;
  align-items: center;
  white-space: nowrap;
  font-weight: 700;

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
  .subtitle-container {
    display: inline-flex;
    padding-top: 10px;

    .subtitle::after {
      content: "|";
      position: relative;
      font-weight: 900;
      padding: 0 8px;
    }
  }
  .page-title {
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 28px;

    /* Title 2 - Bold */
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 33.6px */
  }
  .series-recording-title {
    align-items: center;
    color: #fff;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Title 2 - Bold */
    font-family: "Open Sans";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 33.6px */
    display: inline-flex;
    align-items: center;
    gap: 16px;
  }
  .recordings-total {
    display: flex;
    padding: 0px 0px 0px;
    padding-top: 5px;
    flex-direction: row;
    align-items: center;
    color: #b0b0b0;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Body 1 - Regular */
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 135%; /* 27px */
    margin-left: 16px;
  }
  @media only screen and (max-width: 1366px), only screen and (max-width: 1024px), only screen and (max-width: 768px) {
    .recordings-total {
      margin-left: 0px;
    }
    .recordings-page-header {
      padding-left: 0px;
    }
  }
  .recordingBack {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 112px;
    }
  }
  .action-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
    padding: 8px 16px;

    &:hover {
      background-color: $modal-accent;
    }

    .action-recording-icon {
      margin-right: 10px;
      margin-top: 5px;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .rec-delete {
      img {
        width: 30px;
      }
    }
  }
}

.recording-series-metadata {
  display: flex;
  flex-direction: row;
}
.series-recording-title {
  color: #fff;

  text-align: left;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Open Sans;
}
.recordings-total {
  color: #b0b0b0;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Body 1 - Regular */
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 27px */
  padding-left: 16px;
  margin-top: 4px;
}
.recording-delete-button-container {
  margin-left: auto;
  display: flex;
}
.recording-refine-components-container {
  margin-left: auto;
  display: flex;

  .refine-buttons-container {
    display: flex;
    align-items: center;
    margin-left: 8px;
    min-width: 100px;
    min-height: auto;
    padding: 8px 0;

    img {
      height: 20px;
    }
  }
}

.recording-sort-options,
.recording-filter-options {
  position: relative;
  top: 8px;
}
.recording-filter-options {
  right: 0px;
}

.recording-refinement-list {
  position: absolute;
  overflow: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  background-color: #232323;
  z-index: 3;
  width: max-content;
  max-height: 425px;
  overflow: auto;
  padding-top: 20px;
  padding-bottom: 15px;
  top: 92px;
  right: 126px;
  .dropdown-items-container {
    height: 42px;
  }
}
.recordings-page-header .sort-options {
  top: 92px;
}
.recording-null-state-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 341px;
  .recordings-null-series-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    text-align: center;
    margin-bottom: 24px;
  }
  .recordings-null-series-message {
    color: #b0b0b0;
    font-size: $medium-font-size;
  }
}
.recordings-null-message {
  white-space: pre-wrap;
  width: 917px;
  margin-top: 51px;
  font-size: $medium-font-size;
  line-height: 1.25;
  margin-left: 48px;
  color: #b0b0b0;
}

@media only screen and (max-width: 1600px) {
  .recordings-section-container {
    margin-left: 117px;
  }

  .recordings-null-message {
    width: 720px;
    font-size: $small-font-swimlane;
  }
  .recordings-page-header {
    margin-left: 32px;
    margin-right: 32px;
  }
}
@media only screen and (max-width: 1366px) {
  .recording-series-metadata {
    flex-direction: column;
  }
  .recordings-total {
    flex-direction: column;
  }
  .recording-null-state-wrapper {
    .recordings-null-series-title {
      font-size: $medium-font-binge-watch;
      font-weight: 600;
      margin-bottom: 18px;
    }
    .recordings-null-series-message {
      font-size: $small-font-swimlane;
    }
  }
}

@media only screen and (min-width: 50px) and (max-width: 768px) {
  .recordings-section-container {
    box-sizing: border-box;
  }
  .recordings-total {
    flex-direction: row;
  }
}

@media only screen and (max-height: 1000px) {
  .recording-null-state-wrapper {
    top: 280px;
  }
}

@media only screen and (max-height: 768px) {
  .recording-null-state-wrapper {
    top: 185px;
  }
}
