@import "../../variables.scss";

.avatar-button {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  color: $white;
  text-decoration: none;
  text-align: center;
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: transform 0.2s;
  width: $avatar-size;

  &:hover {
    transform: $hover-emphasis;
    .avatar,
    .avatar-placeholder::after {
      border: 4px solid $white;
      padding: 4px;
      margin: -8px;
    }

    .avatar-placeholder::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .avatar,
  .avatar-placeholder,
  .avatar-placeholder::after {
    width: $avatar-size;
    height: $avatar-size;
    border-radius: 50%;
  }

  .avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $schedule-bg;
    font: $primary-font;
    overflow: hidden;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      -webkit-box-orient: vertical;
      padding: 0 14px;
    }
  }

  .edit-icon {
    position: absolute;
    top: calc(#{$avatar-size} - 60px);
    right: 0;
    width: 60px;
    height: 60px;
  }

  .kids-icon {
    margin-top: 16px;
    height: 24px;
  }

  .avatar-title {
    margin-top: 16px;
    font: $primary-font;

    &.overflow {
      width: 12ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .avatar-subtitle {
    margin-top: 9px;
    font: $regular-font;
    font-size: $cta-btn-font-size;
    color: $gray;
  }
}
