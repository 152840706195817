.sort-filters {
  position: fixed;
  right: 32px;
  padding-top: 32px;
  display: flex;
  width: auto;
  justify-content: space-between;
  z-index: 9;
  background-color: black;
}
