@import "../../variables.scss";

.detail-movie {
  position: relative;
  .wall-progressBar-wrap {
    .progress-bar-container {
      margin: 12px 0;
      position: relative;
      height: 3px;
      background: $translucent-white;
    }
    .progress-bar {
      height: 3px;
      background-color: $subscribe-link;
      max-width: 100%;
    }
  }
}
.detail-swimlane {
  overflow: hidden;
  position: relative;
  margin-top: 30px;
}
.detail-rating-info {
  text-align: center;
  overflow: hidden;
  position: relative;
  margin-top: 30px;
}

.detail-page-cta {
  margin-top: 8px;
  .button {
    width: 438px;
    border-radius: 8px;
    margin: 0 24px 0 0;
    font: $regular-font;
    font-size: $cta-btn-font-size;
    font-weight: 600;
    height: 50px;
  }
}
.wall-children {
  display: flex;
  align-items: baseline;
  margin-top: 50px;

  .expiry-message {
    margin: 10px 50px 0 0;
    text-align: center;
    font: $regular-font;
    font-size: $medium-font-size;
    color: $gray;
  }
}

.wall-content-icon {
  width: 64px;
  height: 64px;
  background-color: $mineshaft-gray;
  border-radius: 50%;
  display: inline-flex;
  margin: 0 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &:hover {
    background-color: $dim-gray;
  }
  &:last-child {
    margin-right: 0;
  }
}
.wall-recording-icon {
  img {
    width: 30px;
    height: 30px;
  }
}

.up-sell-message {
  display: flex;
  align-items: center;
  font: $regular-font;
  font-size: $medium-font-size;
  padding: 18px 24px 17px;
  border-radius: 8px;
  background-color: $mineshaft-gray;
  margin-right: 50px;
  .up-sell-icon {
    width: 30px;
    height: 30px;
    margin-right: 24px;
  }
  .up-sell-link {
    text-decoration: underline;
    color: $subscribe-link;
  }
}

.login {
  margin-top: 30px;
}

.button-container {
  display: inline;
}
.movie-wall-wrapper {
  min-height: 797px;
  position: relative;
  .content-wrapper {
    position: absolute;
    bottom: 0;
    margin-bottom: 43px;
  }
}
@media only screen and (max-width: 1366px) {
  .detail-page-cta .button {
    font: $regular-font-mhd;
    font-weight: 600;
    height: 48px;
    width: 355px;
  }
  .wall-children .expiry-message {
    font-size: $medium-font-size-mhd;
  }
  .wall-content-icon {
    width: 50px;
    height: 50px;
    img {
      width: 25px;
      height: 25px;
    }
  }
}

.lookback-string {
  margin: 10px 50px 0 0;
  text-align: center;
  font: $regular-font;
  font-size: $medium-font-size;
  color: $gray;
}

@media only screen and (max-width: 768px) {
  .detail-page-cta .button {
    width: 200px;
  }
  .wall-children {
    width: 290px;
  }
  .live-btn .button {
    font-size: $small-font-swimlane;
  }
}
