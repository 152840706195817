@import "../../variables.scss";

.recording-sidebar-icon {
  width: 40px;
  height: 40px;
  padding: 10.8px;
  border-radius: 50%;
  background-color: $translucent-white;
  &:hover {
    background-color: $dim-gray;
  }
  img {
    max-width: 18px;
  }
}

@media only screen and (max-width: 1366px) {
  .recording-sidebar-icon {
    width: 25px;
    height: 25px;
    padding: 7px;
    img {
      width: 12px;
      height: 12px;
    }
  }
}
