@import "../../variables.scss";

.onDemand-wrap {
  padding: 15px 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  transition: transform 0.2s;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $schedule-bg;
  min-height: 110px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  .details-wrapper {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .network-logo {
    height: 40px;
    width: auto;
    margin-bottom: 8px;
    img {
      max-height: 40px;
      width: auto;
    }
  }

  .onDemand-action {
    font-size: $medium-font-size;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: $primary-font-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;

    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:not(:first-child) {
        font: $regular-font;
        font-size: $medium-font-size;
        color: $gray;
      }
    }
  }
  .action-wrapper {
    margin-left: auto;
    min-width: 45px;
    padding-left: 10px;
    text-align: center;
  }
  .play-icon {
    width: 40px;
    height: 40px;
    background-color: $translucent-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-left: 4px;
    }
    &.order-icon img {
      margin-left: 0px;
    }
  }
  .progress-bar-container {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: $translucent-white;
    .progress-bar {
      background-color: $subscribe-link;
      height: 100%;
    }
  }
  & .action-wrapper:hover {
    cursor: pointer;
    button {
      background-color: $translucent-gray;
    }
  }
}
