@import "../../variables.scss";
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 38px;
  margin: 0 27px;
  height: 16px;
  border-radius: 8px;
}
.toggle-switch input[type="checkbox"] {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: $scorpion-gray;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  top: -3px;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: $gray;
  border-radius: 50%;
  transition: transform 0.3s ease;
  transform: translateX(-10px);
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(25px);
  background-color: $white;
}
.toggle-switch input[type="checkbox"]:focus + .switch::before {
  outline: auto;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: $strong-green;
}
