@import "../../variables.scss";

.episode-detail {
  position: relative;
  padding-top: 118px;
}

.episode-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: grid;
}
.episode-info {
  margin: 0 45px;
}

.episode-content {
  display: flex;
  gap: 10px;
}
.episode-poster {
  position: relative;
  background-color: $shark-gray;
  height: 273px;
  min-width: 485px;
}
.episode-poster img {
  width: 485px;
  height: 273px;
  border-radius: 4px;
}
.episode-desc {
  overflow: hidden;
  .no-series-header {
    margin: 0 0;
  }
}
.episode-details {
  margin-left: 10px;
}
.episode-swimlane {
  overflow: hidden;
  position: relative;
  top: 40px;
}
.recording-badge {
  margin: 8px 16px 0px 0px;
}

.wall-recording-icon {
  img {
    width: 30px;
    height: 30px;
  }
}
.recording-button-wrapper {
  width: 64px;
  position: relative;
  margin-right: 15px;
}
.record-page-loading {
  width: 64px;
  height: 64px;
  position: absolute;
  top: 0;
  animation: rotation 2s linear infinite;
}

.episode-desc h1 {
  margin: 0px;
  font: $primary-font;
  font-size: $large-font-size;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: inherit;
}

.episode-desc h2 {
  font: $regular-font;
  font-size: $medium-font-size;
  margin: 11px 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $gray;
  margin-top: 10px;
}

.episode-desc p {
  margin: 2px 0px;
  color: $white;
  font: $regular-font;
  font-size: $medium-font-size;
  line-height: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  max-width: 900px;
  &.expirationTime {
    margin-top: 20px;
    color: $white;
    span {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
.clr {
  clear: both;
}
.ep-progress-bar-container {
  background-color: $translucent-white;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.ep-progress-bar {
  height: 4px;
  background-color: $subscribe-link;
  max-width: 100%;
}
.episode_btn {
  display: inline-flex;
}
.episode_btn button {
  position: relative;
  margin-right: 20px;
  margin-top: 50px;
  width: auto;
  padding: 12px 40px;
  height: auto;
  &:not(:first-child) {
    margin-left: 20px;
  }
}
.eps-badge-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin-right: 12px;
    margin-top: 5px;
    img {
      display: block;
      margin-top: 2px;
    }
    &.dv {
      margin-left: 12px;
    }
    &.new {
      margin-bottom: 4px;
    }
    &.live {
      margin-bottom: 4px;
    }
    &._4k {
      margin-bottom: 4px;
    }
    &._4kHDR {
      margin-bottom: 4px;
    }
  }
}
.subscribe {
  position: relative;
  margin-right: 20px;
  margin-top: 50px;
  width: auto;
  line-height: 1.35;
  padding: 12px 16px;
  height: auto;
  font: $primary-font;
  border: none;
  margin-bottom: 40px;
  border-radius: 8px;
  background-color: $mineshaft-gray;
  display: flex;
  line-height: 26px;
  align-items: center;
  & img {
    height: 30px;
    width: 30.1px;
    margin: 0 13.9px 0 0;
  }
  & a {
    cursor: pointer;
    color: $subscribe-link;
  }
}
.wall-content-icon {
  margin-left: 0px;
  width: 64px;
  height: 64px;
}
.up-sell-message {
  display: flex;
  align-items: center;
  font: $regular-font;
  font-size: $medium-font-size;
  padding: 18px 24px 17px;
  border-radius: 8px;
  background-color: $mineshaft-gray;
  margin-right: 50px;
  .up-sell-icon {
    width: 30px;
    height: 30px;
    margin-right: 24px;
  }
  .up-sell-link {
    text-decoration: underline;
    color: $subscribe-link;
  }
}

@media only screen and (max-width: 1600px) {
  .episode-details {
    width: 89%;
  }
}

@media only screen and (max-width: 1366px) {
  .subscribe {
    font-size: $medium-font-size-mhd;
    line-height: 20px;
    & img {
      height: 20px;
      width: 20px;
    }
  }
  .record-page-loading {
    width: 50px;
    height: 50px;
  }
}
@media only screen and (max-width: 1024px) {
  .episode-poster {
    height: 182px;
    min-width: 324px;
  }
  .episode-poster img {
    width: 324px;
    height: 182px;
  }
  .episode-desc h1 {
    font-size: $large-font-size-hd;
    margin-bottom: 2px;
  }
  .episode-desc h2 {
    font-size: $medium-font-size-hd;
    margin: 4px 0px;
  }
  .episode-desc p {
    font-size: $medium-font-size-hd;
  }
}
@media only screen and (max-width: 768px) {
  .subscribe {
    font-size: $small-font-size;
    line-height: 16px;
    & img {
      height: 15px;
      width: 15px;
    }
  }
}

.episode-back-btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  & span {
    font: $regular-font;
    font-size: $medium-font-size;
    color: $white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: inherit;
    font-weight: bold;
  }
  & button {
    margin-left: 15px;
    transition: all ease-in-out 0.2s;
    height: 20px;
    & img {
      height: 19px;
    }
    @media only screen and (max-width: 1024px) {
      margin-left: 7px;
      height: 15px;
      margin-top: 3px;
      & img {
        height: 12px;
      }
    }
  }
}

.btn-cursor {
  cursor: pointer;
}

.lookback-string {
  margin: 10px 50px 0 0;
  text-align: center;
  font: $regular-font;
  font-size: $medium-font-size;
  color: $gray;
}
