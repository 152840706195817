@import "../../variables.scss";

.detail-series {
  position: relative;
}
.detail-swimlane {
  overflow: hidden;
  position: relative;
  margin-top: 30px;
}
.season-dropdown {
  margin: 0 45px 0 46px;
  position: relative;
  width: 416px;
  height: 51px;
  z-index: 9;
  background-color: $modal-accent;
  border-radius: 8px;
  display: inline-flex;
}
.season-detail {
  width: 100%;
  padding: 40px 0;
  background: #1e1e1e 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 30px;
  box-sizing: border-box;
  overflow: hidden;
  min-height: 520px;
}

.season-detail .slick-initialized .slick-slide {
  padding-bottom: 10px;
}
.season-detail .slick-list {
  padding: 30px 0px !important;
}

.season-dropdown {
  .button-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 25px;
    padding-right: 20px;
    .dropdown-icon-container {
      margin-top: 3px;
    }
    &:hover {
      background: transparent;
    }
  }
}
.favouriteIconContainer {
  width: 64px;
  height: 64px;
  background-color: $mineshaft-gray;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.favouriteIconContainer:hover {
  background-color: $dim-gray;
}

.favouriteIconContainer button {
  padding-top: 5px;
}
.series-wall-wrapper {
  min-height: 797px;
  position: relative;
  .content-wrapper {
    position: absolute;
    bottom: 0;
    margin-bottom: 43px;
  }
}
@media only screen and (max-width: 1366px) {
  .season-dropdown {
    top: 25px;
    width: 325px;
    margin-bottom: 30px;
  }
  .season-dropdown {
    .button-container {
      .dropdown-icon-container {
        margin-top: 7px;
      }
    }
  }
  .favouriteIconContainer {
    width: 50px;
    height: 50px;
    & img {
      width: 25px;
      height: 25px;
    }
  }
}

.season-dropdown {
  .popUpContainer {
    .dropdownList {
      margin-top: 0px;
    }
  }
}

.lookback-string {
  margin: 10px 50px 0 0;
  text-align: center;
  font: $regular-font;
  font-size: $medium-font-size;
  color: $gray;
}

@media only screen and (max-width: 768px) {
  .season-dropdown {
    top: 25px;
    width: 270px;
  }
  .season-detail {
    height: 520px;
  }
  .favouriteIconContainer {
    margin-bottom: 10px;
  }
}
