@import "../../variables.scss";

$channelLogoWidth: 68px;
$channelLogoRightPadding: 8px;
$iconHeight: 18px;
$iconPadding: 4px 0 0 8px;
$thumbnailBorderThickness: 2px;
$thumbnailCornerRadius: 4px;

.item-container {
  &:not(.no-hover) {
    transition: transform 0.2s ease-in-out;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
    line-height: 135%;
    max-height: 112px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  .title {
    font: {
      weight: 600;
      size: 20px;
    }
    color: $white;
  }

  .thumbnail-container {
    position: relative;
    overflow: hidden;

    .thumbnail {
      background-color: $silver-gray;
      background-clip: padding-box;
      border-radius: $thumbnailCornerRadius + $thumbnailBorderThickness;
      border: $thumbnailBorderThickness solid rgba(0, 0, 0, 0);
    }

    .progress-bar-container {
      position: absolute;
      left: $thumbnailBorderThickness;
      bottom: $thumbnailBorderThickness;
      height: 52px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      border-bottom-left-radius: $thumbnailCornerRadius;
      border-bottom-right-radius: $thumbnailCornerRadius;

      .progress-bar-background {
        position: absolute;
        left: 8px;
        bottom: 8px;
        right: 8px;
        height: 4px;
        border-radius: 2px;
        background: rgba(255, 255, 255, 0.6);

        .progress-bar {
          height: 4px;
          max-width: 100%;
          border-radius: 2px;
          background-color: $subscribe-link;
        }
      }
    }

    .thumbnail-icon {
      position: absolute;
      right: 10px;
      bottom: 20px;
      width: 35px;
      height: 35px;
    }

    .text-over-image {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font: {
        size: 17px;
        weight: bold;
      }
      text-align: center;
      color: $white;
      line-height: 25px;
      width: 90%;
      overflow: hidden;
      overflow-wrap: break-word;
    }
  }
  .thumbnail-container.team-thumbnail {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #343434;
    .thumbnail {
      height: 80px;
      width: 107.5px;
      border: none;
    }
  }

  .thumbnail-container-cast {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: $thumbnailBorderThickness solid rgba(0, 0, 0, 0);

    .thumbnail {
      background-color: $silver-gray;
    }
  }

  .metadata-container {
    display: flex;
    flex-direction: row;
    margin-top: 8px - $thumbnailBorderThickness;
    margin-left: $thumbnailBorderThickness;

    .channel-logo {
      width: 68px;
      height: 51px;
      padding-right: 8px;
    }

    .title-container {
      display: flex;
      flex-direction: row;

      .recording-icon {
        height: $iconHeight;
        padding: $iconPadding;
      }

      .status-icon {
        height: $iconHeight;
        width: 18px;
        padding: $iconPadding;
      }
    }

    .subtitle {
      font: {
        weight: 400;
        size: 18px;
      }
      color: $gray;
    }
  }

  .metadata-container-episode {
    margin-top: 8px - $thumbnailBorderThickness;
    margin-left: $thumbnailBorderThickness;

    .subtitle-container {
      display: flex;

      .status-icon {
        margin-top: 6px;
        margin-left: 8px;
        width: 16px;
        height: 16px;
      }
    }

    .subtitle {
      font: {
        weight: 400;
        size: 20px;
      }
      color: $gray;
    }

    .episode-record-icon {
      margin: 2px 0 0 7px;
    }

    .description {
      font: {
        weight: 400;
        size: 20px;
      }
      color: $gray;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: normal;
      margin-top: 8px;
      margin-bottom: 7px;
    }
  }

  .details-link {
    display: flex;
    align-items: center;
    font: {
      weight: 600;
      size: 20px;
    }
    color: $white;

    span {
      margin-right: 8px;
    }

    img {
      height: 13px;
    }
  }
}

.item-container:not(.no-hover):hover {
  transform: $hover-emphasis;

  .thumbnail-container {
    .thumbnail {
      border: $thumbnailBorderThickness solid $white;
    }
  }
  .thumbnail-container.team-thumbnail {
    .thumbnail {
      border: none;
    }
  }

  .thumbnail-container-cast {
    border: $thumbnailBorderThickness solid $white;
  }
}

@media only screen and (max-width: 1366px) {
  .item-container {
    .title {
      font-size: 16px;
    }

    .thumbnail-container {
      .thumbnail-icon {
        width: 28px;
        height: 28px;
      }

      .text-over-image {
        font-size: 16px;
      }
    }

    .metadata-container {
      .channel-logo {
        width: 48px;
        height: 36px;
      }

      .title-container .recording-icon {
        height: 14px;
      }

      .title-container .status-icon {
        height: 14px;
        width: 14px;
      }

      .subtitle {
        font-size: 14px;
      }
    }

    .metadata-container-episode {
      .subtitle-container .status-icon {
        width: 12px;
        height: 12px;
        margin-top: 4px;
        margin-left: 6px;
      }

      .subtitle {
        font-size: 16px;
      }

      .description {
        font-size: 16px;
      }
    }

    .details-link {
      font-size: 16px;
    }
  }
}

:export {
  channelLogoWidth: $channelLogoWidth;
  channelLogoRightPadding: $channelLogoRightPadding;
  thumbnailBorderThickness: $thumbnailBorderThickness;
}
