@import "../../variables.scss";

.pin-modal-backdrop {
  width: 100%;
  height: 100%;
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.6;
}

.pin-modal-container {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.pin-modal-popup-container {
  display: table-cell;
  vertical-align: middle;
}

.pin-modal-popup {
  width: 400px;
  z-index: 10;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pin-modal-heading {
  font: $regular-font;
  margin: 0;
  font-weight: 600;
  max-width: 336px;
  text-align: center;
  color: $white;
}

.pin-modal-subheading {
  max-width: 336px;
  font: $dropdown-regular-font;
  text-align: center;
  line-height: 1.44;
  margin: 20px 0 0 0;
  color: $gray;
}

.pin-modal-pin-container {
  display: flex;
  margin-top: 24px;

  &.has-error input {
    border-color: $cinnabar-red;
  }

  input {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    border: solid 2px $dove-gray;
    background-color: rgba(255, 255, 255, 0.1);
    margin: 0 12px;
    font: $regular-font;
    font-weight: 600;
    font-size: 40px;
    color: $white;
    text-align: center;

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.pin-modal-error-string {
  margin: 8px 0 0;
  text-align: center;
  font: $regular-font;
  font-size: 12px;
  line-height: 19px;
  color: $gray;
  max-width: 336px;
  word-spacing: 1px;
}

.pin-modal-cta {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .pin-modal-cancel-cta {
    background-color: transparent;
    font: $regular-font;
    color: $primary-font-color;
    font-size: $medium-font-size;
    height: 50px;
    width: 336px;
    border: none;
    margin: 0;
    cursor: pointer;
    border-radius: 10px;
    border: solid 2px $dove-gray;
  }

  .pin-recover-link {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-size: $medium-font-size;
    line-height: 42px;
  }
}

@media only screen and (max-width: 1366px) {
  .pin-modal-cta {
    .pin-recover-link {
      font-size: $medium-font-size-mhd;
    }
    .pin-modal-cancel-cta {
      font-size: $medium-font-size-mhd;
    }
  }
}

@media only screen and (max-width: 768px) {
}
