@import "../../variables.scss";

.site-header {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 11;
}

.top-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $primary-color;
  height: $top-nav-height;
  min-width: 768px;

  &.kids {
    background: linear-gradient(181.56deg, $orchid-purple 1.32%, #371e4f 98.68%);
  }
  > div {
    flex: 1;
  }
}

.display-flex {
  display: flex;
}

/* This is a temp style for language toggle button */
.login-button {
  font: $primary-font;
  font-size: $cta-btn-font-size;
  height: 51px;
  border: none;
  min-width: 189px;
  padding: 9px 62px 12px 62px;
  border-radius: 8px;
  background-color: $secondary-color;
  color: $primary-font-color;
  cursor: pointer;
  margin-right: 20px;
  line-height: 26px;
  &:hover {
    background-color: $tertiary-color;
  }
}

.align-center {
  align-items: center;
}

.position-relative {
  position: relative;
}

.nav-right {
  display: inline-flex;
  justify-content: flex-end;
}

.search-bar-container {
  height: 100%;
}

.settings-button-container {
  width: 58px;
  height: 100%;
  border-radius: 50%;
  background-color: transparent;
  margin-right: 15px;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  position: relative;

  .settings-icon-container {
    display: inline-flex;
    border-radius: 50%;
    width: 58px;
    height: 58px;
    box-sizing: border-box;
    padding: 13px;
    & button {
      width: 32px;
      margin: 0;
      &:hover:after {
        content: "";
        width: 100%;
        left: 0;
        height: 3px;
        background: $white;
        position: absolute;
        bottom: -25px;
      }
      & img {
        width: 100%;
      }
    }
  }
  &.active .settings-icon-container {
    & button:after {
      content: "";
      width: 100%;
      left: 0;
      height: 3px;
      background: $white;
      position: absolute;
      bottom: -25px;
    }
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .login-button {
    padding: 12px 16px;
    min-width: 136px;
  }
}

@media only screen and (max-width: 1366px) {
  .login-button {
    font-size: $medium-font-size-mhd;
    line-height: 20px;
    padding: 9px 40px 12px 40px;
    height: auto;
    min-width: 136px;
  }
  .settings-button-container {
    width: 50px;
    .settings-icon-container {
      width: 50px;
      height: 50px;
      & button {
        width: 32px;
        &:hover:after {
          bottom: -29px;
        }
      }
    }
    &.active .settings-icon-container {
      & button:after {
        bottom: -29px;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .login-button {
    font-size: $medium-font-size-mhd;
    line-height: 16px;
    padding: 9px 16px 12px 16px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .settings-button-container {
    width: 34px;
    height: 100%;
    margin-right: 7px;
    &.active:after {
      width: 18px;
      left: 8px;
    }
  }
  .settings-icon-container {
    width: 34px;
    height: 34px;
    padding: 8px;
    & button {
      width: 18px;
    }
  }

  .top-nav {
    box-sizing: border-box;
  }
}
