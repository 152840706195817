@import "../../variables.scss";

.recordings-list {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &.no-title {
    margin-top: 30px;
  }

  li {
    list-style: none;
    width: calc(100% - 50px);
    box-sizing: border-box;
    margin-bottom: 24px;
    background-color: #0b0b0b;
    border-radius: 4px;
    .recording-wrap {
      display: grid;
      align-items: center;
      grid-template-columns: 84.8% 15.5% 8.4%;
      .program-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
          width: 354px;
          height: 212px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
      }
      .program-title {
        font-weight: bold;
        font-size: $medium-font-size;
        width: 200px;
        position: relative;
        padding-right: 20px;
      }
      .series {
        cursor: pointer;
      }
      .program-info {
        overflow: hidden;
        padding-left: 32px;
        padding-top: 20px;
      }
      .program-info span {
        padding-bottom: 8px;
      }
      .program-info-description {
        color: $gray;
        font: $table-font;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .series-info {
        color: $gray;
        font: $table-font;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
      }
      .event-info {
        color: $white;
        font: $table-font;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
      }
      .program-running-info {
        span {
          font: $table-font;
          overflow: visible;
          text-overflow: ellipsis;
          padding-right: 24px;
        }

        .line-one {
          color: $white;
        }

        .channel-4k-badge {
          width: 29.33px;
          height: 17.33px;
          padding-top: 5px;
        }

        .expiration-text {
          color: $mango-tango;
        }

        .record-badge {
          & img {
            width: 153px;
            height: 18px;
          }
        }
      }
      .program-recording-actions {
        display: flex;
        align-items: center;
        flex-direction: column;

        .edit-button-container {
          position: relative;
        }

        .edit-icon,
        .delete-icon {
          position: relative;
          margin: 0;
          width: 40px;
          min-width: 40px;
          height: 40px;
          min-height: 40px;
          margin-bottom: 10px;
          img {
            width: 20px;
            height: 20px;
          }
          cursor: pointer;
        }
      }
      .conflict-PVR-info {
        .button-name {
          left: 10px;
          bottom: 0;
          color: $mango-tango;
          font: $table-font;
        }
        & img {
          width: 18px;
          height: 16px;
        }
      }
    }
  }
  li:first-child {
    border-top: none;
  }
  li.has-play-icon:hover {
    background-color: #181818;
  }
  .program-info-wrapper {
    overflow: hidden;
    position: relative;
  }

  .program-title-info {
    white-space: nowrap;
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .program-title-info.hover-active {
    overflow-x: hidden; /* Hide horizontal scrollbar initially */
  }

  .program-title-info.hover-active:hover {
    overflow-x: auto; /* Enable horizontal scrollbar on hover */
    animation: scrollAnimation 3s linear forwards; /* Adjust animation duration as needed */
    text-overflow: initial;
    width: fit-content;
    overflow: visible;
  }

  @keyframes scrollAnimation {
    0% {
      transform: translateX(0); /* Start with no translation */
    }
    100% {
      transform: translateX(calc(250px - 100%));
    }
  }

  .program-running-info-wrapper {
    overflow: hidden;
    position: relative;
    padding-top: 8px;
    margin: auto 0 24px;
    display: flex;
    align-items: center;
  }
  .program-running-info {
    white-space: nowrap;
    position: relative;
    display: inline-block;
    width: 100%;
    min-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .program-running-info.hover-active {
    overflow-x: hidden; /* Hide horizontal scrollbar initially */
  }

  .program-running-info.hover-active:hover {
    overflow-x: auto; /* Enable horizontal scrollbar on hover */
    animation: scrollAnimation 3s linear forwards; /* Adjust animation duration as needed */
    text-overflow: initial;
    width: fit-content;
    overflow: visible;
  }

  @keyframes scrollAnimation {
    0% {
      transform: translateX(0); /* Start with no translation */
    }
    100% {
      transform: translateX(calc(250px - 100%));
    }
  }

  .program-info {
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (max-width: 1920px), only screen and (min-width: 1920px) {
  .recordings-list {
    margin-left: 48px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: 1366px), only screen and (max-width: 1600px) {
  .recordings-list {
    margin-left: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px),
  only screen and (min-width: 1024px) and (max-width: 1366px) {
  .program-info {
    padding-left: 24px;
  }
}
@media only screen and (max-width: 1024px), only screen and (max-width: 768px) {
  .recordings-list li .recording-wrap {
    .program-running-info {
      font-size: $medium-font-size-mhd;
    }
  }
}
.recording-play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 70px;
  max-height: 70px;
}
.mr-cursor {
  cursor: default;
}
.cpvr-cursor {
  cursor: pointer;
}

.recording-main {
  display: flex;
}

.recordings-badge {
  padding-left: 5px;
}

@media only screen and (max-width: 1023px) {
  .recordings-list li .recording-wrap .program-image {
    display: none;
  }
}

.recording-progress-bar-container {
  background-color: $translucent-cloudy-gray;
  width: 324px;
  height: 4px;
  border-radius: 2px;
  position: absolute;
  bottom: 20px;
}
.recording-progress-bar {
  height: 100%;
  background-color: $subscribe-link;
  max-width: 100%;
  border-radius: 2px;
}
