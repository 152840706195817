@import "../../variables.scss";

.epg-wrapper {
  overflow: hidden;
}

.epg-filters {
  display: flex;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 16px 0 16px 24px;
  align-items: center;
  position: relative;
  background-color: #0b0b0b;
}

.epg-container {
  position: relative;
}

.no-result-found {
  text-align: center;
  font: $regular-font;
}

@media only screen and (max-width: 1366px) {
  .no-result-found {
    font: $regular-font-mhd;
  }
}

@media only screen and (max-width: 768px) {
  .epg-filters {
    width: 430px;
    padding-left: 20px;
  }
  .no-result-found {
    font: $regular-font-hd;
  }
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 10px solid;
  border-color: $translucent-white $translucent-gray rgba(255, 255, 255, 0.35) $translucent-cloudy-gray;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  background: $mineshaft-gray;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.6;
}
.billboard-wrapper {
  .content-wrapper {
    padding-top: 0;
  }
}

.billboard-wrapper {
  .billboard-content {
    min-height: 500px;
    position: relative;
    box-sizing: border-box;
    padding-top: 50px;
    .content {
      margin-top: 0px;
    }
  }
}

.filter-box {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 6px;

  .filter-input {
    outline: none;
    color: $primary-font-color;
    background: $translucent-white;
    border-color: transparent;
    white-space: normal;
    text-overflow: ellipsis;
    padding-left: 48px;
    width: 294px;
    height: 41px;
    border-radius: 5px;
    font: $dropdown-regular-font;
    @media (prefers-reduced-motion: no-preference) {
      transition: all var(--transition-duration) ease-out, background 0ms;
    }
  }
  .filter-icon {
    position: absolute;
    margin-left: 16px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

.category-dropdown {
  display: flex;
  align-items: center;
}

.cross-icon {
  position: absolute;
  right: 5%;
  cursor: pointer;
  height: auto;
  & img {
    vertical-align: top;
    width: 100%;
  }
}
.channel-search-input {
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 6px;
  width: 100%;
}
.no-channels-found {
  position: absolute;
  width: 100%;
  height: 34px;
  text-align: center;
  font: $regular-font;
  line-height: 33.6px;
  top: 15%;
  font-weight: 600;
  color: #b0b0b0;
}
