@import "../../variables.scss";

.viewAllFilter {
  position: absolute;
  overflow: auto;
  box-shadow: 0px 0px 10px rgba($primary-color, 0.5);
  background-color: $mineshaft-gray;
  z-index: 3;
  border-radius: 6px;
  top: 100px;
  right: 129px;
  width: 378px;
  max-height: 567px;
  min-height: 170px;
  padding-top: 24px;

  .scrollable {
    width: inherit;
    max-height: 350px;
    min-height: 170px;
    margin-bottom: 15px;
  }

  .filter-buttons {
    border-top: 2px solid $abbey-gray-settings;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    align-content: space-between;
  }

  .filter-button {
    cursor: pointer;
    font: $dropdown-regular-font;
    line-height: 20px;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    color: $primary-font-color;
  }

  .filter-button-enabled {
    color: $subscribe-link;
    font-weight: 600;
  }

  .filter-button:hover {
    background-color: $translucent-white;
  }

  .filter-cancel-button {
    align-self: flex-end;
    margin-left: auto;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 1366px) {
  .viewAllFilter {
    width: 296px;
    max-height: 436px;
  }
}
