@import "../../variables.scss";

.ways-to-watch-backdrop {
  width: 100%;
  height: 100%;
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.6;
}

.ways-to-watch-prompt-container {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  .ways-to-watch-container {
    display: table-cell;
    vertical-align: middle;
    .ways-to-watch {
      border-radius: 12px;
      padding: 48px;
      width: 730px;
      z-index: 10;
      margin: 0 auto;
      .ways-to-watch-label-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 48px;
        .ways-to-watch-label {
          font: $regular-font;
          font-size: $large-font-size;
        }
      }
      .watch-option-wrap {
        padding: 15px 24px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 8px;
        transition: transform 0.2s;
        text-overflow: ellipsis;
        white-space: nowrap;
        background-color: $schedule-bg;
        min-height: 110px;
        box-sizing: border-box;
        cursor: pointer;
        .details-wrapper {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          .network-logo {
            height: 40px;
            width: auto;
            margin-bottom: 8px;
            img {
              max-height: 40px;
              width: auto;
            }
          }
          .watch-option-action {
            font-size: $medium-font-size;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            text-align: left;
            color: $white;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            white-space: normal;
          }
        }
        .action-wrapper {
          margin-left: auto;
          min-width: 45px;
          padding-left: 10px;
          text-align: center;
        }
        &:hover {
          background-color: $scorpion-gray;
        }
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .ways-to-watch {
    width: 600px;
  }
}
