@import "./variables.scss";

@mixin shaded-recording-cta {
  background-color: $scorpion-gray;
  border: none;
  &:hover {
    background-color: $dove-gray;
  }
}

@mixin lone-profiles-cta {
  width: 335px;
  margin: 56px auto 0;
}

@mixin profiles-floating-button {
  position: absolute;
  top: 40px;
  right: 40px;
  width: auto;
  z-index: 10;
  padding: 16px;
  border-radius: 30px;
  background-color: $translucent-white;

  &:hover {
    background-color: opacify($translucent-white, 0.1);
  }

  span {
    padding: 0 4px;
  }
}

@mixin profiles-form {
  display: flex;
  flex-direction: column;
  max-width: 736px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    text-align: left;
    & + .form-row {
      margin-top: 40px;
    }
  }

  .form-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    &.cta-container {
      margin-top: 80px;

      button {
        width: 100%;
        max-width: 356px;
      }
    }
  }

  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $avatar-size;

    .avatar-error {
      width: 100%;
      margin-top: 20px;
      font: $regular-font;
      font-size: $small-font-size;
      color: $cinnabar-red;
      line-height: 19px;
      text-align: center;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 500px;

    .text-input-container {
      display: flex;
      flex-direction: column;
    }
    .text-input {
      font-weight: 600;
    }

    .disabled-setting {
      opacity: 0.5;
    }
  }

  .toggle-setting-container {
    display: flex;
    margin-top: 24px;
    padding: 0 16px;
    border-bottom: 0.5px solid $scorpion-gray;
    font: $regular-font;

    &:first-of-type ~ .toggle-setting-container {
      margin-top: 16px;
    }

    span {
      font-size: $medium-font-size;
    }

    p {
      margin: 4px 0 16px;
      font-size: $small-font-size;
      line-height: 19px;
    }

    .toggle-switch {
      min-width: 38px;
      margin-left: 24px;
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    max-width: 650px;

    h1 {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
    }

    .form-row {
      justify-content: center;

      & > *:not(:first-child) {
        margin-top: 24px;
      }
    }
  }
}
