@import "../../variables.scss";

$channel-cell-width: 201px;
$program-row-height: 70px;
$timeline-row-height: 50px;
$epg-grid-height: calc(100vh - 159px);
$sticky-column-gradient: linear-gradient(90deg, #090909 0%, #262626 35.87%);

.epg-container {
  .epg-grid {
    box-sizing: border-box;
    overscroll-behavior: none;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .epg-grid {
    width: 100%;
    height: $epg-grid-height;
  }

  .cell {
    box-sizing: border-box;
    border: 1px solid $black;
    text-decoration: none;
  }

  .time-cell {
    background-color: $mineshaft-gray;
    height: $timeline-row-height;
    display: inline-flex;
    vertical-align: top;
    justify-content: flex-start;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    cursor: pointer;
    top: 0;
    z-index: 4;

    &.corner {
      top: 0;
      left: 0;
      width: $channel-cell-width;
      background: $sticky-column-gradient;
      z-index: 6;

      button {
        width: 50px;
        height: 50px;
      }
    }

    &.on-now,
    &.lookback {
      z-index: 5;
    }

    .current-time-indicator {
      height: 100%;
      position: absolute;
      top: 0;
      border-right: 2px solid $subscribe-link;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: -7px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 10px solid $subscribe-link;
      }
    }

    span {
      position: absolute;
      color: $white;
      font-size: $medium-font-size;
      padding: 0 16px;
      height: 24px;
    }
  }

  .timeline-placeholder {
    height: $timeline-row-height;
    display: inline-block;
    vertical-align: top;
  }

  .channel-cell {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    width: $channel-cell-width;
    height: $program-row-height;
    background-color: $mineshaft-gray;
    background: $sticky-column-gradient;
    font-size: $medium-font-size;
    font-weight: 600;
    color: $oslo-gray;
    z-index: 4;

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      transition: all 0.1s ease;
      opacity: 1;

      img {
        height: 16px;
        margin-bottom: 4px;
      }

      .number {
        line-height: 26px;
      }
    }

    .fav-button-container {
      position: absolute;
      top: 8%;
      left: 10px;
      width: 65px;
      height: 90%;
      z-index: 1;
      img {
        width: 24px;
        height: 21px;
      }

      &:hover {
        // sibling selector
        + .info {
          opacity: 0;
        }
        button {
          left: 55%;
        }
      }

      button {
        position: absolute;
        top: 50%;
        left: -100%;
        transform: translate(-50%, -50%); // position button in the centre
        padding: 10px; // for a bigger click target (good for accessibility)
        transition: all 0.25s ease;
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      img {
        max-width: 100%;
        max-height: $program-row-height - 10px;
      }
    }
  }

  .placeholder-cell {
    background-color: $cod-gray;
  }

  .program-cell {
    display: flex;
    align-items: center;
    padding: 0 16px;
    position: absolute;
    height: 100%;
    background-color: $cod-gray;
    font-size: $medium-font-size;
    z-index: 3;
    box-sizing: border-box;

    &.on-now,
    &.lookback {
      background-color: $schedule-bg;
    }

    &:hover,
    &.lookback:hover,
    &.on-now:hover {
      background-color: $scorpion-gray;
    }

    .program-name {
      flex: 1;
      display: flex;
      min-width: 0; // prevents the div from getting longer than the flex container

      .sticky-header {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        position: -webkit-sticky;
        position: sticky;
        left: calc(201px + 16px); // channel cell width + program cell padding
        overflow: hidden;
        margin-right: -3px; // removes extra space after the ellipsis

        span {
          font-size: $medium-font-size;
          color: $white;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        span.title-badge {
          font-size: $small-font-size-hd;
          font-weight: 700;
          color: $strong-green;
        }
      }
    }

    .icon-container {
      margin-left: 11px;
      display: flex;
      flex-direction: row-reverse; // anchor icons to the right
      align-items: center;
      gap: 24px;
      img {
        max-height: 18px;
      }
    }
  }

  .epg-chevron {
    position: absolute;
    top: 0;
    height: $timeline-row-height;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      width: 64px;
      height: 100%;
      opacity: 0.6;
    }

    &.left {
      left: 190px;
      padding-left: 10px;
      &::before {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 36.01%, #000000 100%);
      }
      & img {
        width: 12px;
        height: 20px;
      }
    }

    &.right {
      right: 0;
      padding-left: 10px;
      &::before {
        background: linear-gradient(270deg, #000000 36.01%, rgba(0, 0, 0, 0.0001) 100%);
      }
      & img {
        width: 12px;
        height: 20px;
      }
    }

    button {
      position: absolute;
      width: 72px;
      height: 72px;
    }
  }
}

.datetime-picker-overlay {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #232323;
  border-radius: 6px;
}

.datetime-picker-container {
  width: 417px;
  height: auto;
  border-radius: 6px 0px 0px 0px;
  margin: 24px 32px 16px 32px;
}

.date-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.selected-date-text {
  margin-left: 10px;
  margin-right: 10px;
}

.curtain-background {
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 50%;
}

.time-cell.corner span {
  position: static;
  padding: 0;
}
