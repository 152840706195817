@import "../../variables.scss";
.spinner-backdrop {
  position: fixed;
  top: 82px;
  width: 100%;
  height: 100%;
  background: $translucent-black;
  opacity: 1;
  left: 0;
  z-index: 11;
  .spinning-loader {
    left: 50%;
    top: calc(50% - 82px);
  }
}
.spinning-loader {
  border-radius: 50%;
  position: absolute;
  width: 64px;
  height: 64px;
  animation: rotation 1s linear infinite;
}
.contextual-loader-wrapper {
  .spinning-loader {
    right: 64px;
    bottom: 64px;
  }
}
