@import "../../variables.scss";

.breadcrumb-segment {
  font: $primary-font;
  font-size: $large-font-size;

  .breadcrumb-link {
    color: $gray;
    text-decoration: none; /* Remove underline */
    &:hover {
      color: $white;
    }
  }

  .breadcrumb-separator {
    margin: 0 16px;
    color: $gray;
  }
}
