@import "../../variables.scss";

.list-label {
  color: $dropdown-label-color;
  font: $dropdown-regular-font;
  line-height: 22px;
  cursor: auto;
  padding-left: 30px;
}
.list-items {
  position: relative;
  margin-top: 5px;
}
.list-items div {
  list-style: none;
  font: $dropdown-regular-font;
  color: $gray;
  cursor: pointer;
  padding: 10px 40px;
  line-height: 25px !important;
  white-space: pre-line;
}
.list-items div::first-line {
  color: $primary-font-color;
  font-size: $medium-font-size;
  line-height: 35px;
}
.list-items div:hover {
  background-color: $modal-accent;
}
.selected-item::first-line {
  font: $dropdown-primary-font !important;
  line-height: 25px;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  opacity: 0.5;
}

.selected-item img {
  width: 16px;
  height: 16px;
  position: absolute;
  padding-top: 5px;
  right: 25px;
}
.alt-text {
  width: 280px;
  display: block;
  color: $oslo-gray;
}

@media only screen and (max-width: 1366px) {
  .list-items div {
    font: $regular-font-hd;
    line-height: 38px;
  }
  .selected-item {
    font: $primary-font-hd !important;
    line-height: 38px !important;
  }
  .selected-item img {
    height: 12px;
    padding-top: 6px;
  }
  .list-label {
    font: $regular-font-hd;
    line-height: 22px;
  }
}
@media only screen and (max-width: 768px) {
  .list-items div {
    font: $regular-font-hd;
    font-size: $small-font-size-hd;
  }
  .selected-item {
    font: $primary-font-hd !important;
    font-size: $small-font-size-hd !important;
  }
  .selected-item img {
    height: 9px;
    padding-top: 8px;
  }
  .list-label {
    font: $regular-font-hd;
    font-size: $small-font-size-hd;
    line-height: 15px;
  }
}
