@import "../../variables.scss";
@import "../../mixins.scss";

.select-avatar-container {
  .avatar-grid {
    margin-top: 80px;
  }
  .skip-button {
    @include profiles-floating-button;
  }
}

.add-profile-form {
  @include profiles-form;
}
