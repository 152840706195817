@import "../../variables.scss";
.container {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .form-wrapper {
    width: 400px;
    background-color: $schedule-bg;
    text-align: center;
    padding: 32px;
    border-radius: 10px;
    box-sizing: border-box;
    .heading {
      width: 336px;
      font: $primary-font;
      margin-bottom: 24px;
      text-align: center;
    }
    button {
      font: $primary-font;
      border: none;
      width: 100%;
      height: 51px;
      margin-top: 25px;
      margin-bottom: 25px;
      border-radius: 8px;
      background-color: $secondary-color;
      color: $primary-font-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: transparent;
      }
    }
    .description {
      color: #b0b0b0;
      margin-bottom: 25px;
      font: $table-font;
      white-space: pre-wrap;
    }
    .link {
      color: $strong-green;
      text-decoration: underline;
    }
    .sub-description {
      color: #b0b0b0;
      font: $table-font;
    }
  }
}
