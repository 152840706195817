.video-player {
  transition: all ease-in-out 0.4s;
  text-align: left;
  &.shrink {
    text-align: center;
    .show-side-panel {
      display: none;
    }
  }
  & video {
    width: 100%;
    height: 100%;
  }
}
.video-sidebar {
  width: 540px;
  transition: all ease-in-out 0.2s;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  &.closed {
    right: -540px;
    .panel,
    .sidebar {
      display: none;
    }
  }
}
.player-with-sidebar {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

@media only screen and (max-width: 1366px) {
  .video-sidebar {
    width: 455px;
    &.closed {
      right: -455px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .video-sidebar {
    width: 350px;
    &.closed {
      right: -350px;
    }
  }
}
