@import "../../variables.scss";
.dotsContainer {
  display: flex;
  bottom: 36px;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
}
.dotsWelcomeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: $translucent-gray;
  border-radius: 50%;
  margin: 9px;
  &:hover {
    cursor: pointer;
  }
  &.selected {
    width: 18px;
    height: 18px;
    background-color: $subscribe-link;
    border: 0px;
  }
}
