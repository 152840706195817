@import "../../variables.scss";

.recording-setting-backdrop-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $translucent-black;
  top: 0;
  left: 0;
  z-index: 12;
}
.recording-setting-panel {
  width: 586px;
  transition: all ease-in-out 0.2s;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $schedules-table-bg;
  display: flex;
  flex-flow: column;
  z-index: 12;
  padding: 70px 80px 20px 80px;
  box-sizing: border-box;
  overflow: auto;
  .recording-setting-panel-title {
    line-height: inherit;
    font: $primary-font;
    margin-bottom: 40px;
    font-size: 32px;
    margin-top: 70px;
    & > span {
      display: block;
      font-weight: normal;
      padding-top: 5px;
      font-size: 20px;
      color: $episode-info-font-color;
    }
  }
  .recording-dropdown-container {
    width: 100%;
    margin-bottom: 15px;
    color: $gray;
    .recording-dropdown-title {
      color: $episode-info-font-color;
      font-size: $medium-font-size-mhd;
      margin-bottom: 12px;
    }
    .recording-dropdown:nth-child(2).has-newline {
      height: 64px;
      padding-top: 20px;
    }
    .recording-dropdown {
      position: relative;
      height: 46px;
      background-color: $modal-accent;
      border-radius: 12px;
      .button-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;
        border-radius: 12px;
        height: 46px;
        .dropdown-icon-container {
          margin-top: 0px;
        }
        &.active {
          background: $mineshaft-gray;
        }
        .dropdown-button::first-line {
          color: $primary-font-color;
          font-size: $medium-font-size;
        }
      }
    }
  }
  .recording-confirmation-button-wrapper {
    display: flex;
    flex: 1;
    align-items: flex-end;
    justify-content: center;
    .button {
      flex: 0 0 49%;
      height: 46px;
      border-radius: 8px;
      font: $regular-font;
      margin: 0 1%;
      font-size: $rent-btn-font-size;
      display: inline-block;
      &.cancel {
        background-color: #1e1e1e;
        font-size: $rent-btn-font-size;
        border: solid 2px $dove-gray;
      }
    }
  }
  .recording-setting-button-wrapper {
    align-items: flex-start;
    display: flex;
    margin-top: 15px;
    margin-bottom: 30px;
    .recording-image-button {
      display: flex;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background: $schedule-bg;
      flex-flow: wrap;
      justify-content: center;
      align-items: flex-end;
      margin-right: 25px;
      margin-bottom: 60px;
      .button-name {
        font-size: 12px;
        bottom: -15px;
      }
    }
  }
}
.setting-spinner-loader {
  right: 293px;
  z-index: 11;
  width: 0;
}

@media only screen and (max-width: 1366px) {
}
