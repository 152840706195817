@import "../../variables.scss";
.welcome-modal-popup {
  border-radius: 12px;
  .close-modal {
    position: absolute;
    z-index: 3;
    top: 63px;
    right: 63px;
    .imageButton {
      width: 20px;
      img {
        width: 100%;
      }
    }
  }
  &::before {
    background-image: linear-gradient(to top, $mineshaft-gray 11%, #111);
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 170px;
    top: 0;
    left: 0;
    border-radius: 12px 12px 0 0;
    z-index: 0;
  }
  &::after {
    background-image: linear-gradient(to bottom, $mineshaft-gray, #111);
    display: block;
    position: absolute;
    content: "";
    width: 100%;
    height: 215px;
    bottom: 0;
    left: 0;
    border-radius: 0 0 12px 12px;
    z-index: 0;
  }
  .welcome-slide-container {
    padding: 114px 60px 0;
    display: flex;
    justify-content: center;
  }
  .welcome-slide-title-container {
    z-index: 1;
    position: relative;
    text-align: center;
  }
}
.welcome-item {
  border-radius: 12px;
  width: inherit;
  text-align: center;
  padding: 0 22px;
  z-index: 1;
}
.carousel-wall-button {
  width: 324px;
  height: 51px;
  margin: 20px auto 0;
}
.Welcome-item-image {
  width: 526px;
  height: 296px;
  overflow: hidden;
}
.frame {
  width: 526px;
  height: 296px;
  margin: 114px 22px 16px 102px;
}
.welcome-modal-backdrop {
  width: 100%;
  height: 100%;
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.6;
  &.reveal-banner {
    top: $message-banner-height;
  }
}
.welcome-modal-container {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.welcome-modal-popup-container {
  display: table-cell;
  vertical-align: middle;
}
.welcome-modal-popup {
  width: 730px;
  height: 642px;
  z-index: 10;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
}
.left-chevron {
  width: 20px;
  display: inline-block;
  margin-top: 190px;
  flex: 0 0 20px;
}
.carousel-image {
  padding: 12px;
  position: relative;
  width: 526px;
  box-sizing: border-box;
}
.thumbnail-border {
  position: absolute;
  top: 0;
  left: 0;
}
.navigation-text {
  font-size: $medium-font-size;
  width: max-content;
  &:hover {
    cursor: pointer;
  }
  &.guest-mode {
    margin: 30px auto 0;
    .next-carousel {
      padding: 0 0 0 10px;
    }
  }
}
.right-chevron {
  width: 20px;
  display: inline-block;
  margin-top: 190px;
}
.welcome-popup-title {
  font-size: $large-font-size;
  display: flex;
  justify-content: center;
  color: $white;
  &.large-title {
    font-size: $xlarge-font-size;
    margin-top: 0px;
  }
}
.welcome-popup-subtitle {
  font-size: $medium-font-size;
  color: $gray;
  text-align: center;
  display: block;
  margin: 16px 0 35px;
  &.large-subtitle {
    font-size: $medium-font-size;
    max-width: 405px;
    margin: 0 auto;
  }
}
.carousel-login-button {
  padding-top: 40px;
  & .carousel-wall-button {
    margin-top: 0;
    width: 455px;
    box-sizing: border-box;
    height: 60px;
    &:focus {
      background-color: $strong-green;
    }
  }
}
.carousel-text {
  padding-top: 30px;
  text-align: center;
  font-size: $medium-font-size;
  color: $gray;
}
.next-carousel {
  padding: 5px;
}
.chevronLink-image-color {
  filter: opacity(0.5) drop-shadow(0 0 0 $gray);
}
.welcome_bookmark_wrapper {
  background-color: $schedule-bg;
  border: solid 1px $abbey-gray-settings;
  display: flex;
  border-radius: 30px;
  width: 430px;
  margin: 40px auto;
  padding: 10px 15px 10px 20px;
  font-size: 22px;
  font-weight: bold;
  line-height: 24px;
  align-items: center;
  span:last-child {
    margin-left: auto;
  }
}

@media only screen and (max-width: 768px) {
  .welcome-modal-backdrop.reveal-banner {
    top: $small-message-banner-height;
  }
}
