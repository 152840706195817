@import "../../variables.scss";
.recording-list-container {
  position: absolute;
  opacity: 1;
  overflow: auto;
  box-shadow: 0px 3px 6px $shadow-black;
  background: $mineshaft-gray;
  z-index: 3;
  border-radius: 12px;
  top: 50px;
  width: 100%;
  max-height: 255px;
  min-height: 100px;
  padding-bottom: 5px;
  padding-top: 5px;
  &.has-newline {
    top: 90px;
  }
}
.recording-list-container .scrollable {
  width: 100%;
  max-height: inherit;
  min-height: inherit;
}
.recording-dropdown {
  .button-container {
    img {
      width: 13px;
      height: 11px;
    }
  }
}
.recording-dropdown-list {
  .list-label {
    margin: 0;
    padding: 0;
  }
  .list-items {
    padding-bottom: 5px;
    div {
      padding: 10px 0;
      padding-left: 20px;
    }
    .selected-item img {
      right: 20px;
    }
  }
}
@media only screen and (max-width: 1366px) {
  .recording-dropdown-list {
    .selected-item img {
      right: 19px;
      padding-top: 8px;
      height: 11px;
    }
  }
  .recording-dropdown {
    .button-container {
      img {
        width: 8px;
        padding-right: 6px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .recording-dropdown-list {
    .selected-item img {
      right: 15px;
      padding-top: 9px;
      height: 9px;
    }
  }
  .recording-dropdown {
    .button-container {
      img {
        width: 8px;
        padding-right: 5px;
      }
    }
  }
}
