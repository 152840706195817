@import "../../variables.scss";

.channel-panel-backdrop-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: $translucent-black;
  top: 0;
  left: 0;
  z-index: 11;
}
.channel-favourite-panel {
  width: 540px;
  transition: all ease-in-out 0.2s;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $schedules-table-bg;
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  .channel-favourite-panel-title {
    line-height: inherit;
    font: $primary-font;
    margin: 16px 48px 24px;
  }
  li {
    list-style: none;
    border-top: solid 1px $abbey-gray-settings;
    box-sizing: border-box;
    &:first-child {
      border-top: none;
    }
  }
}
.channel-fav-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  &:hover {
    background: $translucent-white;
    cursor: pointer;
  }
}
.channel-favourite-icon {
  width: 64px;
  height: 64px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 24px;
}
.panel-scrollable-channels {
  overflow: auto;
  height: 100vh;
}
.panel-channel-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 123px;
  margin-left: 24px;
}
.panel-channel-number {
  font-size: $medium-font-size-mhd;
  max-width: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.panel-channel-logo {
  img {
    width: 52px;
    height: 39px;
  }
}
.panel-channel-name {
  width: 317px;
  margin-left: 20px;
  font-size: $medium-font-size-mhd;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.side-panel-top {
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  z-index: 4;
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
}
.close-panel {
  display: flex;
  background: $translucent-white;
  border-radius: 30px;
  width: 84px;
  height: 51px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-right: 24px;
  align-self: flex-end;
}
/*Chrome*/
.panel-scrollable-channels::-webkit-scrollbar-track {
  width: 4px;
}

.panel-scrollable-channels::-webkit-scrollbar-thumb {
  background-color: $dropdown-scroll-color;
  border: 1px solid transparent;
  background-clip: content-box;
  border-radius: 3px;
}

.panel-scrollable-channels::-webkit-scrollbar {
  width: 5px;
}

@media only screen and (max-width: 1366px) {
  .channel-favourite-panel {
    width: 480px;
    .channel-favourite-panel-title {
      font-size: $medium-font-size;
    }
  }
  .channel-favourite-icon {
    width: 50px;
    height: 50px;
    img {
      width: 25px;
      height: 25px;
    }
  }
  .panel-channel-name {
    width: 280px;
  }
}
