@import "./variables.scss";

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  src: url("../public/fonts/OpenSans-Regular.ttf");
}

body {
  margin: 0;
  background-color: $black;
  color: $white;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $black;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
