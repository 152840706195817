@import "../../variables.scss";
.view-all-wrapper {
  position: relative;
  .sort-filters {
    position: fixed;
    right: 32px;
    padding-top: 36px;
    display: flex;
    width: auto;
    justify-content: space-between;
    z-index: 9;
    background-color: black;
  }
}

@media only screen and (max-width: 780px) {
  .no-result-found {
    font: $regular-font-hd;
  }
}

.no-result-found {
  text-align: center;
  font: $regular-font;
  margin-top: 200px;
}
