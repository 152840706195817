@import "../../variables.scss";

.filter-list-container {
  position: absolute;
  opacity: 1;
  overflow: auto;
  box-shadow: 0px 3px 6px $shadow-black;
  background: $mineshaft-gray 0% 0% no-repeat padding-box;
  z-index: 3;
  border-radius: 12px;
  top: 75px;
  width: max-content;
  max-height: 425px;
  padding-top: 21px;
  overflow: hidden;
  .scrollable {
    max-height: 350px;
    margin-bottom: 15px;
    margin-right: 30px;
  }

  .filter-buttons {
    border-top: 2px solid $abbey-gray-settings;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    align-content: space-between;
  }

  .filter-button {
    cursor: pointer;
    font: $dropdown-regular-font;
    line-height: 20px;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    color: $light-gray;
  }

  .filter-button-enabled {
    color: $strong-green !important;
  }

  .filter-button:hover {
    background-color: $modal-accent;
  }

  .filter-cancel-button {
    align-self: flex-end;
    margin-left: auto;
    margin-right: 10px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .filter-list-container {
    left: 67%;
  }
}

@media only screen and (max-width: 1366px) {
  .filter-list-container {
    max-height: 436px;
  }
}

@media only screen and (max-width: 1920px) {
  .filter-list-container {
    max-height: 436px;
  }
}
