@import "../../variables.scss";

.range-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100px;
  border-radius: 11px;
  background: $mineshaft-gray;
  position: absolute;
  left: -17px;
  top: -200px;
  .input:focus {
    outline: auto;
  }
  .input[type="range"] {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 300px;
    height: 5px;
    padding: 0;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }

  /*Chrome thumb*/

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -webkit-border-radius: 5px;
    /*16x16px adjusted to be same as 14x14px on moz*/
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $white;
    border: 1px solid $gray;
  }

  /*Mozilla thumb*/

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    -moz-border-radius: 5px;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: $white;
    border: 1px solid $gray;
  }

  /*IE & Edge input*/

  input[type="range"]::-ms-track {
    width: 300px;
    height: 6px;
    /*remove bg colour from the track, we'll use ms-fill-lower and ms-fill-upper instead */
    background: transparent;
    /*leave room for the larger thumb to overflow with a transparent border */
    border-color: transparent;
    border-width: 2px 0;
    /*remove default tick marks*/
    color: transparent;
  }

  /*IE & Edge thumb*/

  input[type="range"]::-ms-thumb {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: $white;
    border: 1px solid $gray;
  }

  /*IE & Edge left side*/

  input[type="range"]::-ms-fill-lower {
    background: $strong-green;
    border-radius: 2px;
  }

  /*IE & Edge right side*/

  input[type="range"]::-ms-fill-upper {
    background: $white;
    border-radius: 2px;
  }

  /*IE disable tooltip*/

  input[type="range"]::-ms-tooltip {
    display: none;
  }

  input[type="range"]::-ms-fill-lower {
    background-color: $strong-green;
  }
  input[type="range"]::-ms-fill-upper {
    background-color: $white;
  }
}

:export {
  colorGreen: $strong-green;
  colorWhite: $white;
}
