@import "../../variables.scss";
.carousel {
  position: relative;
}

.chevron-container-right {
  position: absolute;
  right: 0;
  width: 80px;
  margin-top: 20px;
}

.chevron-container-left {
  position: absolute;
  left: 0;
  width: 80px;
  margin-top: 20px;
}

.chevron {
  display: none;
  position: absolute;
  z-index: 2;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  width: 86px;
  height: 86px;
  cursor: pointer;
}

.chevron-left {
  transform: rotate(180deg);
  left: 2px;
}

.chevron-right {
  right: 2px;
}

.swimlane-row {
  padding-left: 45px;
}

// .swimlane-row {
//   margin-bottom: 34px;
//   overflow: hidden;
//   box-sizing: border-box;
//   position: relative;
// }

/* Hide scrollbar for Chrome, Safari and Opera */
// .swimlanes-row::-webkit-scrollbar {
//   display: none;
// }

/* Hide scrollbar for IE, Edge and Firefox */
// .swimlanes-row {
//   -ms-overflow-style: none; /* IE and Edge */
//   scrollbar-width: none; /* Firefox */
// }

.swimlane-row:hover {
  & .chevron {
    display: block;
    transition: all ease-in-out 0.2s;
    @media only screen and (max-width: 1366px) {
      width: 56px;
      height: 56px;
    }
    @media only screen and (max-width: 768px) {
      width: 48px;
      height: 48px;
    }
  }
}

.mb-20 {
  margin-bottom: 20px;
}

.slick-list {
  padding: 20px 0px 35px;
  overflow: visible;
  @media only screen and (max-width: 1366px) {
    padding: 17px 0px 30px;
  }
  @media only screen and (max-width: 768px) {
    padding: 15px 0px 25px;
  }
}
.fav-channel-swimlane {
  display: flex;
  align-items: center;
  padding: 20px 0 35px;
  @media only screen and (max-width: 1366px) {
    padding: 17px 0 30px;
  }
}
.fav-channel-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.favourite-channel-null {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: rgba(30, 30, 30, 0.8);
  border: 1px solid $white;
  border-radius: 4px;
  transition: all ease-in-out 0.2s;
}
.favourite-channel-null:hover {
  transform: scale(1.08);
  cursor: pointer;
}
.favourite-text {
  font-size: $medium-font-size-mhd;
  margin-left: 6px;
  width: 168px;
  @media only screen and (max-width: 1366px) {
    margin-left: 12px;
  }
}
.fav-channel-plus-icon {
  width: 24px;
  height: 24px;
}
