@import "../../variables.scss";
.environment {
  white-space: pre-line;
  background-color: red;
}
.getLoggedIn {
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  .form-wrapper {
    width: 470px;
    .error {
      padding: 15px 10px;
      background: #f5a1a1;
      color: #af2121;
      border-radius: 8px;
      border: solid 2px #e67777;
    }
    input {
      font: $primary-font;
      border: none;
      width: 100%;
      height: 51px;
      margin-top: 25px;
      margin-bottom: 25px;
      border-radius: 8px;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
      color: $black;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: transparent;
      }
    }
    button {
      font: $primary-font;
      border: none;
      width: 100%;
      height: 51px;
      margin-top: 25px;
      margin-bottom: 25px;
      border-radius: 8px;
      background-color: $secondary-color;
      color: $primary-font-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:focus {
        outline: transparent;
      }
    }
  }
}
