@import "../../variables.scss";

@keyframes timer {
  from {
    background-position: right bottom;
  }
  to {
    background-position: left bottom;
  }
}

.videoPlayer {
  width: 100vw;
  height: 100vh !important;
}

.video-wrapper {
  outline: none;
}

.gradient-bg {
  position: fixed;
  width: 100vw;
  top: 0;
  height: 100vh;
  opacity: 0;
  transition: opacity 2s;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5816701680672269) 30%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  );
}

.cc-container {
  width: 100%;
  position: fixed;
  bottom: 175px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  p {
    font: $regular-font;
    font-size: $large-font-size-hd;
    margin: 1px;
    padding: 0px 2px 2px 2px;
    background-color: $black;
    text-align: center;
  }
}

.binge-watch-close-icon {
  position: absolute;
  bottom: 0;
  top: 49px;
  left: 59px;
}

.videoControls {
  position: fixed;
  width: 100vw;
  top: 0;
  height: 100vh;
  opacity: 0;
  transition: opacity 2s;
  z-index: 10;

  .metadata {
    width: 100%;
    position: relative;
    .info {
      display: flex;
      direction: row;
      margin-bottom: 30px;
      .channel-info {
        text-align: center;
        .live-channel-number {
          font: $regular-font;
          font-size: $large-font-size-hd;
          line-height: 34px;
          margin-right: 10px;
        }
        img {
          margin-right: 10px;
          width: 60px;
          height: 50px;
          margin-top: 3px;
        }
      }
      .details {
        flex-shrink: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        .title {
          text-align: left;
          font: $primary-font;
          font-size: 25px;
          color: $primary-font-color;
          display: block;
          line-height: 34px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
        }
        .description {
          font: $regular-font;
          color: $secondary-font-color;
          font-size: 22px;
          display: block;
          line-height: 30px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          text-align: left;
        }
      }
    }
    .progressBar-wrap {
      position: relative;
    }
  }

  .listButton {
    position: absolute;
    top: 50%;
    right: 0;
    width: 80px;
    height: 94px;
    margin-top: -47px;
    background: $translucent-black;
    border-radius: 12px 0 0 12px;
    box-sizing: border-box;
    padding: 20px 30px;
    img {
      width: 100%;
    }
  }
  .openIcon {
    img {
      transform: rotate(180deg);
    }
  }

  .playbackControl-top {
    position: absolute;
    top: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
  }
  .playbackControl-bottom {
    position: absolute;
    bottom: 30px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
    &.dis-flex {
      justify-content: flex-start;
    }
  }
  .playbackControl-metaData {
    position: absolute;
    bottom: 110px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
  }
  .dis-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    order: 1;
    &.align-item-start {
      align-items: flex-start;
    }
    &.justify-item-start {
      justify-content: flex-start;
    }
    .back-button-container {
      position: inherit;
      top: auto;
      padding-left: 0;
    }
    button {
      min-width: 35px;
      height: auto;
      margin-right: 25px;
      &:last-of-type {
        margin-right: 0;
      }
      img {
        box-sizing: border-box;
        width: 35px;
        max-height: 40px;
        height: auto;
        display: inline-block;
        vertical-align: top;
      }
      &.imageButton {
        box-sizing: border-box;
        width: 65px;
        height: 65px;
      }
      &.imageButton:hover {
        background: $mineshaft-gray;
        border-radius: 50%;
      }
      &.playbackClose,
      &.playbackFullScreen,
      &.playbackMinimizeScreen {
        img {
          width: 28px;
        }
      }
      &.playbackMinimizeScreen {
        span {
          transform: translateX(-70%) translateY(130%);
        }
      }
      &.volumeButton {
        img {
          width: auto;
          height: 30px;
        }
      }
      &.playbackPlayPause {
        img {
          height: 30px;
          width: auto;
        }
      }
    }
    button:disabled,
    button[disabled] {
      cursor: not-allowed;
    }
  }
  .align-right {
    margin-left: auto;
    order: 2;
  }
}

.player-popup-container {
  margin-right: 30px;
}

.player-popup-container:last-of-type {
  margin-right: 0;
}

.loader-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  .spinning-loader {
    position: relative;
    left: 50%;
    top: 50%;
    margin: -30px 0 0 -30px;
    box-sizing: border-box;
  }
}
.pos-rel {
  position: relative;
}
.next-episode {
  position: absolute;
  bottom: 56px;
  right: 80px;
  width: 860px;
  display: flex;
  height: 129px;
  justify-content: space-between;
  .episode-card {
    width: 230px;
    border-radius: 8px;
    background-size: contain;
  }
  .card-text {
    width: 218px;
    padding-top: 6px;
    div {
      line-height: 1.59 !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .label {
      font: $regular-font;
      font-size: $large-font-size-hd;
    }
    .show-name {
      font: $primary-font;
      font-size: $large-font-size-hd;
    }
    .episode-info-container {
      display: flex;
      .episode-info {
        font: $regular-font;
        font-size: $medium-font-binge-watch;
        color: $episode-info-font-color;
      }
    }
  }
  .binge-watch-button-container {
    display: grid;
    align-items: center;
    width: 266px;
    .binge-watch-buttons {
      width: 100%;
      border-radius: 8px;
      font: $primary-font;
      font-size: $rent-btn-font-size;
      border: none;
      height: 51px;
      color: $primary-font-color;
      cursor: pointer;
    }
    .timer-bg {
      background: linear-gradient(to right, $translucent-gray 50%, $translucent-white 50%);
      background-size: 200% 100%;
    }
    .dismiss-bg {
      background-color: $translucent-white;
    }
  }
}

.volume-slider-container {
  position: absolute;
  top: -10px;
  left: 0;
  height: 10px;
  width: 100px;
}
.record-player-loading {
  width: 65px;
  height: 65px;
  position: absolute;
  right: 40px;
  bottom: 0;
  animation: rotation 2s linear infinite;
}

.still-watching {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 120px;
  box-sizing: border-box;
  &.shrink {
    padding: 0 80px;
  }
  .label {
    font: $primary-font;
    font-size: $large-font-size-hd;
  }
  button {
    width: 266px;
    border-radius: 8px;
    font: $primary-font;
    font-size: $rent-btn-font-size;
    border: none;
    height: 51px;
    color: $primary-font-color;
    cursor: pointer;
    background-color: $translucent-white;
    margin-top: 15px;
  }
}

.shrink {
  width: calc(100% - 540px);
}

@media only screen and (max-width: 1366px) {
  .next-episode {
    width: 650px;
    height: 104px;
    .episode-card {
      width: 185px;
    }
    .card-text {
      width: 180px;
      padding-top: 0;
      .label {
        font-size: $medium-font-binge-watch;
      }
      .show-name {
        font-size: $medium-font-binge-watch;
      }
      .episode-info {
        font-size: $medium-font-size;
      }
    }
    .binge-watch-button-container {
      width: 200px;
      .binge-watch-buttons {
        height: 40px;
      }
    }
  }
  .still-watching {
    padding: 0 80px;
    .label {
      font-size: $medium-font-binge-watch;
    }
    button {
      width: 200px;
      height: 40px;
    }
  }

  .shrink {
    width: calc(100% - 455px);
  }
}

@media only screen and (max-width: 1100px) {
  .next-episode {
    width: 600px;
    .card-text {
      padding-top: 6px;
      .label {
        font-size: $medium-font-size;
      }
      .show-name {
        font-size: $medium-font-size;
      }
      .episode-info {
        font-size: $medium-font-size-mhd;
      }
    }
  }
  .still-watching {
    .label {
      font-size: $medium-font-size;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .shrink {
    width: calc(100% - 350px);
  }
}
.live-player-recording-icon {
  bottom: 0;
  border-radius: 50%;
  background-color: $translucent-white;
  &:hover {
    background-color: $dim-gray;
  }
}
