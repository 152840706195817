@import "../../variables.scss";

.fallback-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.app-logo img {
  width: 237.18px;
  max-width: none;
}

.app-logo .logo-wrapper {
  margin: 0;
}

.error-text {
  margin-top: 29px;
  height: 27px;
  font-size: $medium-font-size;
  line-height: 27px;
}

.warning {
  height: 20px;
  margin-right: 9px;
}
