@import "../../variables.scss";

.hubs {
  font: $regular-font;
  display: inline-flex;
  .hubs-dropdown {
    display: none;
  }
  & ul {
    display: flex;
    margin: 0;
    padding: 0;
    & li {
      list-style-type: none;
      & a {
        line-height: $top-nav-height - 42;
        text-decoration: none;
        display: block;
        color: $primary-font-color;
        white-space: nowrap;
        position: relative;
        margin: 0px 24px;
        &.selected {
          font-weight: 600;
        }
      }
    }
  }
}

li a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transform: scaleX(0);
  transition: transform 0.13s ease;
  border-radius: 50px 50px;
}
a:hover::before {
  transform: scaleX(1);
}

.hubs-dropdown:hover + .hubs-list {
  visibility: visible;
}

/* Style the active link for screen sizes above 1366 */
@media screen and (min-width: 1367px) {
  a.selected::before {
    transform: scaleX(1);
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .hubs {
    font: $medium-font-mhd;
    padding-left: 0px !important;
    & ul li a {
      margin: 0px 20px;
    }
  }
}

@media only screen and (max-width: 1365px) {
  .hubs {
    color: #fff;
    z-index: 20;
    ul {
      flex-direction: column;
      padding: 15px 0px;
      &:hover .selected-hub::before {
        transform: scaleX(0);
      }
    }
    ul li {
      color: $white;
      cursor: pointer;
      &:hover {
        background-color: $translucent-white;
      }
    }
    & ul li a {
      margin: 0;
      padding: 7px 24px;
      &:after {
        display: none;
      }
      &:hover::before {
        transform: scaleX(0);
      }
    }
    .hubs-dropdown {
      display: inherit;
    }
    .hubs-list {
      visibility: hidden;
    }
  }

  .selected-hub {
    position: relative;
    font-weight: 600;
  }

  .selected-hub::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    transform: scaleX(0);
    transition: transform 0.13s ease;
    border-radius: 50px 50px;
  }

  .hubs-dropdown {
    color: #fff;
    font-weight: 600;
    align-items: center;
    background: none;
    border: 0;
    font: $regular-font;
    font-size: 24px;
    line-height: normal;
    padding: 7px 16px;
    text-align: left;
    cursor: pointer;
    span {
      padding: 2px;
      img {
        width: 15px;
        height: 11px;
        transform: rotate(90deg);
      }
    }
    &:hover .selected-hub::before {
      transform: scaleX(1);
    }
  }

  .hubs-list {
    position: absolute;
    background-color: $mineshaft-gray;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.38);
    top: 90px;
    width: 168px;
    font-size: $small-font-size;
    transition: visibility 0.1s ease;
    &:hover {
      visibility: visible;
    }
    a {
      padding: 5px 0;
    }
  }
}

@media only screen and (max-width: 768px) {
  .hubs .hubs-list {
    top: 90px;
  }
}
