@import "../../variables.scss";

.bottom-rating .rating-logo {
  width: 200px;
}
.bottom-rating .score {
  margin-top: 26px;
  margin-bottom: 60px;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    .rating-separator {
      border-left: 1px solid $dark-gray;
      height: 20px;
      margin-left: 16px;
      margin-right: 16px;
      &:last-child {
        border-left: none;
        height: 0;
        margin: 0;
      }
    }
  }
  .rt {
    &:nth-child(even) {
      margin-right: 12px;
    }
    &:nth-child(odd) {
      margin-right: 24px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  .rating-text {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: $white;
  }
  .cinoche {
    margin-right: 4px;
  }
}
