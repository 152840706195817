@import "../../variables.scss";
.seasonListContainer {
  position: absolute;
  opacity: 1;
  overflow: auto;
  box-shadow: 0px 3px 6px $shadow-black;
  background: $mineshaft-gray 0% 0% no-repeat padding-box;
  z-index: 3;
  border-radius: 12px;
  top: -5px;
  width: 416px;
  max-height: 255px;
  min-height: 170px;
  padding-bottom: 25px;
  padding-top: 25px;
}
.seasonListContainer .scrollable {
  width: 100%;
  max-height: inherit;
  min-height: inherit;
}
.season-dropdown {
  // .dropdown-icon-container {
  //   position: absolute;
  //   left: 89%;
  // }
  .button-container {
    img {
      width: 17px;
      height: 15px;
    }
  }
}
.season-picker-list {
  .list-items div {
    padding: 10px 0;
    padding-left: 25px;
  }
}
@media only screen and (max-width: 1366px) {
  .seasonListContainer {
    width: 300px;
  }
  .season-picker-list {
    .selected-item img {
      right: 19px;
      padding-top: 8px;
      height: 11px;
    }
  }
  .season-dropdown {
    .button-container {
      img {
        width: 8px;
        padding-right: 6px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .seasonListContainer {
    width: 270px;
  }
  .season-picker-list {
    .selected-item img {
      right: 15px;
      padding-top: 9px;
      height: 9px;
    }
  }
  .season-dropdown {
    .button-container {
      img {
        width: 8px;
        padding-right: 5px;
      }
    }
  }
}
