.logo-wrapper {
  margin-left: 45px;
  margin-right: 30px;
  display: flex;
  align-items: center;
  & img {
    padding: 0;
    max-width: 157px;

    &.kids-logo {
      height: 19px;
      margin-left: 6px;
    }
  }
}

@media only screen and (min-width: 1366px) and (max-width: 1600px) {
  .logo-wrapper {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 1024px) {
  .logo-wrapper {
    margin-left: 24px;
    margin-right: 1px;
    & img {
      max-width: 126px;
    }
  }
}
