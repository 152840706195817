@import "../../variables.scss";

.profile-setup-modal {
  position: fixed;
  top: calc(#{$top-nav-height} + 19px);
  right: 24px;
  width: 400px;
  min-height: 544px;
  background: linear-gradient(180deg, $orchid-purple 0%, $bastille-black 53.65%);
  box-shadow: 0px 4px 4px rgba($black, 0.38);
  border-radius: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;

  > * {
    margin: 12px auto;

    &:first-child {
      margin-top: 40px;
    }
    &:last-child {
      margin-bottom: 40px;
    }
  }

  // Triangle pointing toward top nav profile icon
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    right: 20px;
    border-bottom: 15px solid $orchid-purple;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    z-index: 15;
  }

  img {
    width: 282px;
    filter: drop-shadow(0px 2px 4px rgba($black, 0.35));
  }

  .profile-setup-text {
    width: 352px;
    text-align: center;
    h1 {
      margin: 0;
      font: $primary-font;
      line-height: 34px;
    }
    p {
      margin-top: 16px;
      margin-bottom: 0;
      font-size: $medium-font-size;
      color: $gray;
    }
  }

  .cta-container {
    height: 116px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    button {
      width: 336px;
      height: 50px;
      margin: 0;
      border-radius: 10px;
      font: $regular-font;
      font-size: $medium-font-size;
      font-weight: 600;

      .user-is-tabbing &:focus {
        outline: auto;
      }
    }
  }
}

.profile-setup-modal-backdrop {
  width: 100%;
  height: 100%;
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.5;
}

@media only screen and (max-width: 1366px) {
  .profile-setup-modal {
    .profile-setup-text {
      h1 {
        font-size: $medium-font-size;
      }

      p {
        font-size: $medium-font-size-mhd;
      }
    }

    .cta-container button {
      font-size: $medium-font-size-mhd;
    }
  }
}
