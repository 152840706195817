@import "../../variables.scss";

.list-button {
  height: 39px !important;
  padding: 6px 19px 6px 17px;
  border-radius: 19px;
  background-color: $translucent-white;
  border: none;
  cursor: pointer;
  font: $primary-font-mhd;
  color: $primary-font-color;
}
