@import "../../variables.scss";

.dropdown-button {
  background-color: transparent;
  border-width: 0px;
  padding: 0px;
  cursor: pointer;
  border-radius: 5px;
  line-height: 25px;
  white-space: pre-line;
}
.recording-dropdown .dropdown-button::first-line {
  font-weight: 600;
  line-height: 30px;
}
.button-container:hover {
  background-color: $modal-accent;
}

.button-container {
  min-width: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font: $dropdown-regular-font;
  line-height: 20px;
  padding: 5px;
  border-radius: 5px;
}

.labelTag {
  color: $secondary-font-color;
  font-weight: 600;
}

.dropdown-icon-container {
  margin-left: 15px;
  transform: rotate(90deg);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .button-container {
    flex: 1;
  }
}

@media only screen and (min-width: 1366px) {
  .button-container {
    font-size: $medium-font-size;
  }
}

@media only screen and (max-width: 1366px) {
  .button-container {
    min-width: 76px;
    font-size: $medium-font-size-mhd;
  }
  .dropdown-button {
    line-height: 23px;
  }
}
@media only screen and (max-width: 768px) {
  .button-container {
    min-width: 43px;
    font: $regular-font-hd;
    line-height: 21px;
  }
  .button-container img {
    width: 9px;
    height: 9px;
  }
  .dropdown-button {
    line-height: 21px;
  }
}
