@import "../../variables.scss";

.purchase-modal-backdrop {
  width: 100%;
  height: 100%;
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0.6;
}

.purchase-modal-container {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.purchase-modal-popup-container {
  display: table-cell;
  vertical-align: middle;
}

.purchase-modal-popup {
  width: 636px;
  z-index: 10;
  margin: 0 auto;
  padding: 45px 47px 12px 47px;
}

.purchase-modal-header {
  text-align: left;
  font: $regular-font;
  font-size: $large-font-size;
  font-weight: 700;
}

.purchase-item {
  width: inherit;
  display: flex;
  margin-top: 36px;
}

.purchase-item-image {
  width: 166px;
  object-fit: contain;
}

.purchase-item-title {
  font: $primary-font;
  width: 389px;
  line-height: 1.22;
}

.purchase-item-details {
  padding: 0px 36px;
}

.purchase-parameters {
  display: flex;
  flex-direction: column;
}

.purchase-parameter {
  display: flex;
  margin-bottom: 10px;
  margin: 8px 0;
}

.access-message {
  display: block;
  padding-left: 15px;
  color: white;
  margin-left: 18px;
  font: $dropdown-regular-font;
}

.parameter-title {
  font: $dropdown-regular-font;
  color: $gray;
  text-align: right;
  width: 95px;
  font-weight: 600;
}
.parameter-value {
  font: $dropdown-primary-font;
  color: white;
  padding-left: 7px;
  font-weight: 700;
  margin-left: 25px;
}
.purchase-parameter span {
  font-size: $medium-font-size;
}

.purchase-cta {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 48px;
}
.purchase-cta button {
  width: 305px;
  height: 46px;
  font: $regular-font;
  font-size: $medium-font-size;
  font-weight: 600;
  margin-top: 0;
}

.purchase-cancel-cta {
  background-color: transparent;
  color: $primary-font-color;
  border: none;
  margin-top: 15px;
  margin-bottom: 30px;
  cursor: pointer;
  border-radius: 8px;
  border: solid 2px $dove-gray;
}

@media only screen and (max-width: 1366px) {
  .purchase-modal-popup {
    width: 510px;
    padding: 30px 25px 12px 25px;
  }
  .purchase-modal-header {
    font-size: $large-font-size-mhd;
  }
  .purchase-item {
    margin-top: 25px;
  }
  .purchase-item-title {
    font-size: $large-font-size-hd;
    width: 335px;
  }
  .purchase-item-details {
    width: 280px;
  }
  .purchase-item-subtitle {
    font-size: $medium-font-size-mhd;
  }
  .purchase-parameters {
    width: 396px;
  }
  .purchase-parameters p {
    font-size: $medium-font-size-mhd;
  }
  .purchase-parameters span {
    font-size: $medium-font-size-mhd;
  }
  .purchase-cta {
    margin-top: 36px;
  }
  .purchase-cta button {
    width: 240px;
  }
  .purchase-cancel-cta {
    width: 240px;
  }
  .purchase-cancel-cta {
    font-size: $medium-font-size-mhd;
  }
  .purchase-item-image {
    width: 127px;
  }
}

@media only screen and (max-width: 768px) {
}
