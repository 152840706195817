@import "../../variables.scss";

.sort-options {
  position: absolute;
  overflow: auto;
  box-shadow: 0px 0px 10px rgba($primary-color, 0.5);
  background-color: $mineshaft-gray;
  z-index: 3;
  border-radius: 6px;
  top: 100px;
  right: 0;
  width: 367px;
  max-height: 425px;
  min-height: 170px;
  padding-top: 24px;
}

.sort-options .scrollable {
  width: inherit;
  max-height: 350px;
  min-height: 170px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1366px) {
  .sort-options {
    width: 296px;
    max-height: 436px;
  }
}

.sort-buttons {
  border-top: 2px solid $abbey-gray-settings;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  align-content: space-between;
}

.sort-button {
  cursor: pointer;
  font: $dropdown-regular-font;
  line-height: 20px;
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  color: $light-gray;
}

.sort-button-enabled {
  color: $strong-green !important;
}

.sort-button:hover {
  background-color: $modal-accent;
}

.sort-cancel-button {
  align-self: flex-end;
  margin-left: auto;
  margin-right: 10px;
}
