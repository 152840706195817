@import "../../variables.scss";

// TODO: Check with design team to see if this animation needs to be tweaked
@keyframes expand-and-appear {
  0% {
    width: 0;
    opacity: 0;
  }
  30% {
    width: $avatar-size;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes disappear-and-collapse {
  0% {
    width: $avatar-size;
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  50% {
    width: $avatar-size;
  }
  100% {
    width: 0;
  }
}

.profile-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1320px;
  margin: 40px auto 0;

  .avatar-button {
    margin: 40px 20px 0;

    &.newly-added {
      animation: expand-and-appear 0.5s linear;
    }

    &.newly-deleted {
      animation: disappear-and-collapse 0.5s linear;
      width: 0;
      opacity: 0;
      margin-left: 0;
      margin-right: 0;
    }

    .kids-icon {
      margin-top: 12px;
    }
  }

  .add-profile-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 20px 0;
    max-width: $avatar-size;
    height: 267px;
    overflow: visible;
    transition: transform 0.2s;
    color: $white;
    text-decoration: none;
    text-align: center;

    &:hover {
      transform: $hover-emphasis;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: $avatar-size;
      height: $avatar-size;
      border: 2px solid $gray;
      border-radius: 50%;
      background-color: rgba($cod-gray, 0.8);
    }

    span {
      margin-top: 16px;
      font: $primary-font;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .profile-list {
    max-width: 660px;
  }
}
