@import "../../variables.scss";
@import "../../mixins.scss";

.cancel-modal-backdrop {
  width: 100%;
  height: 100%;
  background: $black;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 16;
  opacity: 0.6;
}

.cancel-modal-container {
  display: table;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 16;
}

.cancel-modal-popup-container {
  display: table-cell;
  vertical-align: middle;
}

.cancel-modal-popup {
  width: 395px;
  z-index: 14;
  margin: 0 auto;
  padding: 30px;
  box-sizing: border-box;
}

.cancel-type {
  text-align: center;
  font: $regular-font;
  font-size: 25px;
}

.cancel-item {
  display: table;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 15px;
}

.cancel-subtitle {
  font: $regular-font;
  color: $primary-font-color;
  font-size: $medium-font-size;
  text-align: center;
}

.cancel-item-title {
  background-color: $dark-red;
  font: $regular-font;
  color: $primary-font-color;
  font-size: $medium-font-size;
  text-align: center;
  height: 46px;
  width: 335px;
  border: none;
  margin-top: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  border-radius: 8px;
  border: solid 2px $dove-gray;
  &:hover {
    background-color: $abbey-gray-settings;
  }
  &.shaded {
    @include shaded-recording-cta;
  }
}

.cancel-item-title p {
  margin: 5px 0 8px 0;
  height: 27px;
}
