@import "../../variables.scss";

.parental-control-container {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;

  .heading-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .cancel-button {
      width: 150px;
      height: 50px;
    }
  }
  .description {
    margin: 32px 0;
    color: $gray;
    font: $regular-font;
    font-size: $medium-font-size;

    .pin-info {
      font-weight: bold;
      color: $primary-font-color;
    }
  }
  .text-button {
    background-color: transparent;
    color: $primary-font-color;
    border: 0;
    padding: 0;
    font: $regular-font;
    font-size: $medium-font-size;
    cursor: pointer;

    img {
      margin-left: 8px;
      width: 8px;
      height: 13px;
    }
  }
  .rating-list-container {
    margin-top: 32px;
  }
}
