@import "../../variables.scss";

.content {
  width: 550px;
  padding-left: 45px;
  z-index: 8;
  position: inherit;
  margin-top: 50px;
}
.series-wall-wrapper,
.movie-wall-wrapper {
  .content {
    width: 900px;
  }
}

.content h1 {
  margin: 0 0 11px 0;
  font: $primary-font;
  font-size: $large-font-size;
  line-height: 39px;
}
.wall-progressBar-wrap {
  position: relative;
}
.content h2 {
  font: $dropdown-regular-font;
  font-size: $medium-font-size;
  margin: 0 0 11px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: $gray;
}
.content h3 {
  font: $regular-font;
  font-size: $medium-font-size;
  margin: 9px 0 11px 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.content p {
  margin: 0;
  font: $regular-font;
  font-size: $medium-font-size;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 26px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  -webkit-line-clamp: 4;
}

.wall-button-wrapper {
  padding-left: 45px;
  min-height: 100px;
  & .wall-children > .wall-content-icon {
    margin-left: 10px;
  }
}

.details-badge-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .recording-now-badge {
    margin-right: 16px;
  }
  .line-metadata {
    width: 650px;
  }
  .no-scores-line-metadata {
    width: 791px;
  }
  span:not(:first-child) {
    img {
      display: block;
    }
    &.cc {
      margin-left: 18px;
    }
    &.dv {
      margin-left: 16px;
    }
    &._4k {
      display: flex;
      margin-left: 16px;
      margin-bottom: 4px;
      height: 100%;
      max-height: 17.55px;
    }
    &._4kHDR {
      display: flex;
      margin-left: 16px;
      margin-bottom: 4px;
      height: 100%;
      max-height: 17.55px;
    }
  }
  span {
    img {
      display: block;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .content h1 {
    font-size: $large-font-size-mhd;
    line-height: 33px;
  }
  .content h2 {
    font-size: $medium-font-size-mhd;
    line-height: 23px;
    margin: 0 0 8px 0;
  }
  .content h3 {
    font-size: $medium-font-size-mhd;
    line-height: 23px;
    margin: 6px 0 11px 0;
  }
  .content p {
    font-size: $medium-font-size-mhd;
    line-height: 26px;
  }
}

@media only screen and (max-width: 768px) {
  .content h1 {
    font-size: $large-font-size-hd;
    line-height: 33px;
  }
  .content h2 {
    font-size: $medium-font-size-hd;
    line-height: 20px;
  }
  .content h3 {
    font-size: $medium-font-size-hd;
    line-height: 20px;
  }
  .content p {
    font-size: $medium-font-size-hd;
    line-height: 26px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}
