@import "../../variables.scss";

.radio-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  & .label {
    line-height: 40px;
  }
}
.filter-disabled {
  cursor: default;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

.radio-container input:checked ~ .radio-checkmark {
  border: 1px solid $strong-green;
}

.radio-container .radio-checkmark:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $strong-green;
}

.radio-checkmark {
  position: relative;
  // top: 6px;
  // left: 0;
  height: 16px;
  width: 16px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $white;
  margin-right: 14px;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
