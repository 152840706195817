$white: #fff;
$black: #000;
$dark-green: #2b8000;
$strong-green: #66cc00;
$light-orange: #ffc145;
$orchid-purple: #611c6c;
$dark-purple: #391e50;
$bastille-black: #140f15;
$cinnabar-red: #e93a24;
$dark-red: #c93a24;
$nero-black: #221f1f;
$gray: #b0b0b0;
$light-gray: #aeaeae;
$dark-gray: #404040;
$dim-gray: #696969;
$mineshaft-gray: #232323;
$shark-gray: #2a2c2e;
$scarpa-flow: #59565c;
$alto-white: #d8d8d8;
$pale-sky: #7b7b81;
$abbey-gray: #54595f;
$oslo-gray: #878b91;
$abbey-gray-settings: #434343;
$cod-gray: #1e1e1e;
$tundora-gray: #454545;
$scorpion-gray: #565656;
$schedule-bg: #343434;
$subscribe-link: #60bf02;
$mango-tango: #e17a00;
$translucent-white: rgba(255, 255, 255, 0.15);
$translucent-gray: rgba(255, 255, 255, 0.25);
$primary-color: $black;
$secondary-color: $dark-green;
$tertiary-color: $strong-green;
$translucent-cloudy-gray: rgba(255, 255, 255, 0.5);
$dove-gray: #6f6f6f;
$shadow-black: rgba(0, 0, 0, 0.16);
$translucent-black: rgba(0, 0, 0, 0.5);
$silver-gray: #c4c4c4;

$primary-font-color: $white;
$secondary-font-color: $light-gray;

$secondary-table-font-color: $oslo-gray;

$button-color: $scorpion-gray;

$modal-primary-bg: $mineshaft-gray;
$modal-accent: $shark-gray;

$dropdown-font-color: $alto-white;
$dropdown-label-color: $pale-sky;
$dropdown-scroll-color: $abbey-gray;

$player-progress-bg: $scarpa-flow;
$player-progress-buffering: $black;
$player-progress-indicator: $strong-green;
$player-buffer-indicator: $gray;
$schedules-table-bg: $cod-gray;
$schedules-item-outline: $tundora-gray;

$episode-info-font-color: $gray;
$thumbnail-background-color: $silver-gray;

$regular-font: normal 24px/29px "Open Sans", sans-serif;
$primary-font: Bold 24px/29px "Open Sans", sans-serif;

$dropdown-regular-font: normal 20px/27px "Open Sans", sans-serif;
$dropdown-primary-font: Bold 20px/27px "Open Sans", sans-serif;

$table-font: normal 20px/26px "Open Sans", sans-serif;

$large-font-size-settings-page: 36px;
$large-font-size: 32px;
$medium-font-size: 20px;

//Font for resolution 1366x768 - MHD resolution
$regular-font-mhd: normal 19px/29px "Open Sans", sans-serif;
$medium-font-mhd: normal 22px/26px "Open Sans", sans-serif;
$primary-font-mhd: Bold 19px/29px "Open Sans", sans-serif;

$large-font-size-mhd: 27px;
$medium-font-size-mhd: 18px;
$small-font-size: 14px;
$xlarge-font-size: 48px;
//Font for resolution 768x1024 - HD resolution
$regular-font-hd: normal 16px/29px "Open Sans", sans-serif;
$primary-font-hd: Bold 16px/29px "Open Sans", sans-serif;

$btn-tooltip: Bold 16px "Open Sans", sans-serif;

$large-font-size-hd: 25px;
$large-font-size-hd-app-popup: 26px;
$medium-font-size-hd: 19px;
$small-font-size-hd: 12px;
$medium-font-swimlane: 18px;
$small-font-swimlane: 16px;
$xsmall-font-swimlane: 13px;
$medium-font-binge-watch: 22px;

$rent-btn-font-size: 19px;
$cta-btn-font-size: 22px;

$top-nav-height: 82px;
$message-banner-height: 80px;
$small-message-banner-height: 58px;

$hover-emphasis: scale(1.08);

$avatar-size: 180px;

:export {
  mangoTango: $mango-tango;
  subscribeLink: $subscribe-link;
}
