@import "../../variables.scss";

.playback-error-bg {
  transition: all ease-in-out 0.2s;
  position: absolute;
  width: 100%;
  height: 100%;
  // background-color: $schedules-table-bg;
  z-index: 5;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.5816701680672269) 30%,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 1) 100%
  );

  .playback-error-container {
    position: relative;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 816px;
    .playback-error-header {
      display: flex;
      padding-bottom: 37px;
      .playback-error-icon {
        width: 45px;
        height: 40px;
        padding-right: 24px;
      }
      .playback-error-title {
        font: $primary-font;
        font-size: $large-font-size;
        line-height: 1.22;
      }
    }
    .playback-error-body {
      font: $regular-font;
      font-size: $large-font-size-hd;
      color: $episode-info-font-color;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .playback-error-bg {
    .playback-error-container {
      width: 600px;
      .playback-error-header {
        padding-bottom: 15px;
        .playback-error-icon {
          width: 35px;
        }
        .playback-error-title {
          font-size: $large-font-size-mhd;
          line-height: 1.6;
        }
      }
      .playback-error-body {
        font-size: $medium-font-size;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .playback-error-bg {
    .playback-error-container {
      width: 400px;
      .playback-error-header {
        padding-bottom: 0;
        .playback-error-icon {
          width: 25px;
        }
        .playback-error-title {
          font-size: $medium-font-size;
          line-height: 2;
        }
      }
      .playback-error-body {
        font-size: $small-font-swimlane;
      }
    }
  }
}
