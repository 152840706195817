@import "../../variables.scss";

.video-settings-popup {
  position: absolute;
  right: 0;
  font: normal 24px/29px "Open Sans", sans-serif;
  font-size: 24px;
  font-size: 14px;
  min-width: 200px;
  border-radius: 7px;
  z-index: 1;
  width: 362px;
  bottom: 80px;
  height: auto;
  padding: 10px 0;
}
.title-content {
  display: flex;
  margin-left: 20px;
  align-items: center;
  text-align: left;
  h1 {
    font: $primary-font;
    font-size: $rent-btn-font-size;
    width: 240px;
    img {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;
      margin-top: 6px;
      width: 25px !important;
    }
  }
}
