@import "../../variables.scss";

.profile-screen {
  background-color: $black;
  min-height: calc(100vh - 30px);
  padding-bottom: 30px;

  &.kids {
    background: linear-gradient(252.44deg, $orchid-purple 0.89%, #371e4f 100%);
    position: relative;

    .gradient-overlay {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.7;

      &::before {
        content: "";
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  // The toast notification sits higher up on profile pages
  & ~ .toast-Container {
    top: 40px;
    right: 40px;
    max-width: none;
  }

  .profile-screen-content {
    position: relative;
  }

  .logo-wrapper {
    display: inline-flex;
    margin-top: 13px;

    & + * {
      margin-top: calc(150px - #{$top-nav-height});
    }
  }

  h1 {
    margin: 0;
    text-align: center;
    font: $primary-font;
    font-size: $large-font-size;
  }

  .button {
    margin: 0;
    font: $regular-font;
    font-size: $medium-font-size;
    font-weight: 600;
  }
}
