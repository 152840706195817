@import "../../variables.scss";

.wall {
  position: absolute;
  width: 100%;
  top: 0;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.04) 66%,
      rgba(0, 0, 0, 0.05) 71%,
      $black
    );
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), $translucent-black 60%, $black);
  }
  img {
    width: 100%;
    max-height: 600px;
    min-height: 600px;
    position: relative;
    outline: none;
    object-fit: cover;
    border: none;
    pointer-events: none;
  }
}
.billboard-wrapper,
.series-wall-wrapper,
.movie-wall-wrapper,
.episode-wall-wrapper {
  .wall {
    width: 1067px;
    max-height: 600px;
    right: 0;
    &::before {
      height: 499px;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.937255) 87.03%, #000000 100%);
      bottom: 0;
      top: auto;
    }
    &::after {
      width: 499px;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.937255) 87.03%, #000000 100%);
      bottom: 0;
      top: auto;
    }
  }
}
.episode-wall-wrapper {
  min-height: 611px;
  position: static;
  padding-top: 102px;
  box-sizing: border-box;
  .wall {
    z-index: -1;
    opacity: 0.2;
  }
}
@media only screen and (max-width: 1366px) {
  .billboard-wrapper,
  .series-wall-wrapper,
  .movie-wall-wrapper,
  .episode-wall-wrapper {
    .wall {
      width: 800px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .wall {
    height: 432px;
    background-attachment: unset;
  }
}
