@import "../../variables.scss";

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 11;
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: $translucent-black;
    opacity: 1;
  }
  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    z-index: 12;
    position: absolute;
    .modal-wrap {
      background-color: $mineshaft-gray;
      border-radius: 10px;
      padding: 10px 0 5px 0;
      text-align: center;
      &.sm {
        width: 395px;
      }

      .close-icon {
        display: flex;
        width: 35px;
        height: 35px;
        box-sizing: border-box;
        margin-left: auto;
        & .imageButton {
          margin: 0;
        }
      }
      .close-icon:hover {
        border-radius: 50%;
        background-color: $dim-gray;
      }
      .modal-body {
        padding: 5px 30px 10px 30px;
        color: $primary-font-color;
        font-size: $medium-font-size-mhd;
        word-break: break-word;
      }
      .error-modal-btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        .error-modal-btn-green {
          width: 335px;
          margin-bottom: 0px;
          height: 46px;
          font: $regular-font;
          font-size: $medium-font-size;
          .user-is-tabbing &:focus {
            outline: auto;
          }
        }
        .error-modal-btn-gray {
          background-color: transparent;
          font: $regular-font;
          color: $primary-font-color;
          font-size: $medium-font-size;
          text-align: center;
          height: 46px;
          width: 335px;
          border: none;
          margin-top: 15px;
          margin-bottom: 0px;
          cursor: pointer;
          border-radius: 8px;
          border: solid 2px $dove-gray;
          padding: 5px 0 8px 0;
          &:hover {
            background-color: $abbey-gray-settings;
          }
        }
        .error-modal-btn-focus-gray {
          background-color: $scorpion-gray;
          border: none;
        }
      }

      .error-modal-ext-link {
        display: inline-block;
        font: $regular-font;
        color: $episode-info-font-color;
        font-size: $medium-font-size;
        margin-bottom: 25px;
      }
    }
  }
  .invalid-account-modal {
    .modal-header {
      padding: 0 45px;
    }
  }
}
