@import "../../variables.scss";

.content-locked-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transition: all ease-in-out 0.2s;

  .content-locked-container {
    display: flex;
    max-width: 800px;
    width: 100%;
    margin: 36vh auto 0;

    &::before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: "";
      background-color: $primary-color;
      z-index: 5;
    }

    * {
      z-index: 10;
    }
  }

  .lock-icon {
    width: 28px;
    height: 36px;
  }

  .content-locked-text {
    margin-left: 24px;
    margin-top: 4px;
    font: $regular-font;
    color: $primary-font-color;

    .content-locked-heading {
      font: $primary-font;
      font-size: 28px;
    }
    .content-locked-message {
      margin-top: 24px;
      font-size: $medium-font-size;
      color: $gray;
      white-space: pre-wrap;
    }
    button {
      min-width: 200px;
      width: auto;
      height: auto;
      padding: 16px;
      margin-top: 24px;
      font-size: $medium-font-size;
      border-radius: 10px;
      span {
        line-height: 18px;
      }
    }
  }
}

@media only screen and (max-width: 1366px) {
  .content-locked-overlay {
    .content-locked-container {
      max-width: 650px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .content-locked-overlay {
    .content-locked-container {
      max-width: 400px;
    }
  }
}
