@import "../../variables.scss";

thead tr {
  color: $dove-gray;
}

.rdtCounters {
  display: table;
}

.rdtCounter {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 92px;
  height: 104px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

.rdtCount {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  margin-bottom: 5px;
}

.rdtCounterSeparator {
  position: relative;
  font-size: 0;
  line-height: 3px;
  margin: 43px 20px 0;
}

.rdtCounterSeparator::before {
  content: url("../../../public/images/Ellipse 220.svg");
  display: block;
}

.rdtCounterSeparator::after {
  content: url("../../../public/images/Ellipse 220.svg");
  display: block;
}

.rdtBtn {
  width: 20px; /* Adjust the width */
  height: 20px; /* Adjust the height */
  background-size: contain; /* Maintain aspect ratio */
  background-repeat: no-repeat;
  background-position: center;
}

.rdtBtn:before {
  visibility: hidden; /* Remove the default content */
}

.rdtBtn:first-child {
  content: url("../../../public/images/ico_arrow_up_small.svg");
  visibility: visible;
}

.rdtBtn:last-child {
  content: url("../../../public/images/ico_arrow_up_small.svg");
  visibility: visible;
  transform: rotate(180deg);
}

.time-picker {
  display: flex;
}

.time-picker > span {
  margin-right: 40px;
}

.ampm-container {
  margin-top: 5px;
  width: 97px;
  height: 102px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

.am-pm-container {
  display: flex;
  flex-direction: column;
}

.am-label,
.pm-label {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  font-weight: 600;
  font-size: 24px;
  line-height: 210%;
  color: #6f6f6f;
}

.am-label.active {
  background-color: white;
  color: #343434;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.pm-label.active {
  background-color: white;
  color: #343434;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select-date-text,
.select-time-text {
  display: flex;
  justify-content: space-between;
  height: 32px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24.3px;
  color: #6f6f6f;
  align-items: center;
}

.select-date-text img {
  width: 19px;
  height: 19px;
}

.date-picker {
  table {
    table-layout: fixed;
    width: 400px;
    td {
      font-size: 20px;
      font-weight: 600;
      line-height: 27px;
      color: #fff;
      text-align: center;
      padding: 20px 0 0;
    }
  }
}

.selected-date {
  font-weight: 600;
  font-size: 23px;
  line-height: 38.4px;
  color: #ffffff;
  margin-bottom: 20px;
}

.date-picker .dow {
  color: #6f6f6f;
  text-transform: capitalize;
}

.week-days span {
  font-size: 20px;
  font-weight: 300;
  line-height: 27px;
  text-align: left;
  margin-right: 23px;
  margin-left: 21px;
  color: #6f6f6f;
}

.line-seprator {
  margin: 20px 0;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

.calender-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.calender-reset-button,
.calender-apply-button {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  align-items: center;
  padding: 16px;
}

.rdtDay.current-date {
  color: #60bf02;
}

.rdtDay.current-date.selected-date::before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #458b00;
  color: #fff;
  z-index: -1;
}

.rdtDay.current-date.selected-date {
  color: #fff;
}

.rdtDay {
  position: relative;
  color: #60bf02;
  font-weight: bold;
}

.rdtDay:hover::before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}

.rdtDay.selected-date {
  background-color: transparent;
  color: #333;
}

.rdtDay.selected-date::before {
  content: "";
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  z-index: -1;
}

.center-time-picker {
  display: flex;
  justify-content: center;
}

.calender-reset-button {
  opacity: 0.5;
  cursor: not-allowed;
}

.calender-reset-button.enabled {
  opacity: 1;
  cursor: pointer;
}

.calender-reset-button:hover,
.calender-apply-button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 4px;
}
