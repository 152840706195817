@import "../../variables.scss";

.search-swimlanes {
  position: relative;
  top: 38px;
  margin-bottom: 34px;
  box-sizing: border-box;
  .grid {
    margin-top: 2rem;
    width: 98%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 2rem;

    .grid-item {
      img {
        width: 100%;
        cursor: pointer;
        &:hover {
          border-radius: 8px;
          border: 2px solid white;
          transform: $hover-emphasis;
        }
      }
    }
  }
}

.search-tray-view {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.search-swimlanes::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.search-swimlanes {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-results-label {
  color: $dropdown-scroll-color;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .search-swimlanes {
    top: 39px;
  }
}
