@import "../../variables.scss";

.avatar-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, $avatar-size);
  gap: 40px;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .avatar-grid {
    max-width: 620px;
  }
}
