@import "../../variables.scss";

.purchase-pin-page {
  width: 900px;
  max-width: 100%;
  margin: 64px auto;
}

.purchase-pin-heading {
  margin: 0;
  font: $primary-font;
  color: $primary-font-color;
  font-size: $large-font-size;
}
.purchase-pin-desc {
  margin-top: 50px;
  margin-bottom: 24px;
  font: $regular-font;
  color: $gray;
  font-size: $medium-font-size;
  white-space: pre-wrap;
}

.purchase-pin-toggle-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 0;
  border-top: 1px solid $schedule-bg;
  border-bottom: 1px solid $schedule-bg;

  .toggle-pin-switch {
    display: flex;
  }

  .toggle-label {
    font: $primary-font;
    color: $primary-font-color;
    font-size: $medium-font-size;
  }
}

.purchase-pin-action-container {
  margin-top: 26px;
  margin-bottom: 54px;
  > * {
    margin-right: 24px;
  }
}

@media only screen and (max-width: 1366px) {
  .purchase-pin-page {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .purchase-pin-page {
    width: 550px;
    margin-top: 53px;
  }
}
