@import "../../variables.scss";

:root {
  --transition-duration: 200ms;
}

.search-container {
  display: flex;
  align-items: center;
  margin-right: 45px;
  position: relative;
}
.search-box {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 48px;
  width: 410px;
  padding-left: 16px;
  &:focus-within {
    background: $translucent-white;
    .search-input {
      margin-right: 0px;
      margin-left: 26px;
      font-size: 18px;
      background: transparent;
      opacity: 1;
      cursor: initial;
      @media (prefers-reduced-motion: no-preference) {
        transition: all var(--transition-duration) calc(var(--transition-duration) * 0.1) ease-in, background 0ms;
      }
    }
    .search-icon {
      right: calc(100% - 23px);
      margin-left: 0;
      cursor: initial;
      @media (prefers-reduced-motion: no-preference) {
        transition: all var(--transition-duration);
      }
    }
    .search-icon img {
      width: 20px;
      height: 20px;
      @media (prefers-reduced-motion: no-preference) {
        transition: all var(--transition-duration);
      }
    }
    .cross-icon-container {
      opacity: 1;
    }
  }
}

.search-input {
  outline: none;
  position: relative;
  color: $primary-font-color;
  background: $translucent-white;
  border-color: transparent;
  width: 0;
  opacity: 0;
  white-space: normal;
  text-overflow: ellipsis;
  width: 100%;
  font: $dropdown-regular-font;
  @media (prefers-reduced-motion: no-preference) {
    transition: all var(--transition-duration) ease-out, background 0ms;
  }
}
.search-icon {
  right: 0px;
  @media (prefers-reduced-motion: no-preference) {
    transition: all var(--transition-duration) calc(var(--transition-duration) * 0.2) ease-out;
  }
}
.search-icon img {
  width: 31px;
  height: 31px;
  vertical-align: top;
  @media (prefers-reduced-motion: no-preference) {
    transition: all var(--transition-duration) ease-out;
  }
}

.cross-icon-container {
  position: absolute;
  right: 2%;
  cursor: pointer;
  height: auto;
  & img {
    vertical-align: top;
    width: 100%;
  }
  opacity: 0;
}

@media only screen and (min-width: 1367px) and (max-width: 1600px) {
  .search-box {
    width: auto;
    max-width: 306px;
  }
  .search-container:focus-within {
    margin-right: 32px;
  }
}

@media only screen and (min-width: 1154px) and (max-width: 1366px) {
  .search-box {
    width: 410px;
  }
}

@media only screen and (max-width: 1153px) {
  .search-box {
    width: auto;
    max-width: 306px;
  }
}

@media only screen and (max-width: 1024px) {
  .search-box {
    max-width: 275px;
  }
  .search-container {
    margin-right: 24px;
  }
  .search-input {
    margin-left: 10px;
  }
}
