@import "../../variables.scss";

.subcategoryPage {
  overflow: hidden;
}

.categoryPageHeader {
  width: 100%;
  position: fixed;
  top: 82px;
  z-index: 10;
  background-color: black;
  display: flex;
  padding-left: 48px;
  gap: 16px;
  height: 113px;
  align-items: center;

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
}

.categoryPageHeader h1 {
  font: $primary-font;
  font-size: $large-font-size;
}

.viewAllBack {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 112px;
  }
}

.categoryPageItems {
  position: relative;
  top: 113px;
}
.subCategorySwimlane {
  top: 10px;
}

@media only screen and (max-width: 1366px) {
  .subCategorySwimlane {
    top: 7px;
  }
}

@media only screen and (max-width: 768px) {
  .subCategorySwimlane {
    top: 9px;
  }
  .no-result-text {
    font: $regular-font-hd;
  }
}

.no-result-text {
  text-align: center;
  font: $regular-font;
  margin-top: 200px;
}
