@import "../../variables.scss";

.SettingDetail {
  width: 895px;
  margin: 31px auto 10px;
  .settingDetailList p {
    border-bottom: 1px solid $abbey-gray-settings;
    color: $primary-font-color;
    margin: 0px;
    padding: 24px 0px 24px;
    display: flex;
    justify-content: space-between;
    font-size: $medium-font-size;
    &:last-child {
      border-bottom: 1px solid $abbey-gray-settings;
    }
    span {
      color: $gray;
      max-width: 530px;
      word-break: break-all;
    }
    a {
      color: $gray;
    }
  }
}
.settingDetail-label {
  font: $primary-font;
  color: $primary-font-color;
  font-size: $large-font-size-mhd;
}
.settingDetail-heading {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1366px) {
  .SettingDetail {
    margin-top: 18px;
    .settingDetailList p {
      font: $regular-font-mhd;
    }
  }
}

@media only screen and (max-width: 768px) {
  .SettingDetail {
    width: 550px;
    margin-top: 29px;
  }
}
