@import "../../variables.scss";

.settings-page {
  width: 900px;
  max-width: 100%;
  margin: 64px auto;
}

.settings-row {
  display: flex;
  border-bottom: 1px solid $abbey-gray-settings;
  padding: 24px 0px;
  text-align: left;
  font: $regular-font;
  font-size: $medium-font-size;

  div:nth-child(2) {
    flex: 1;
  }

  .settings-option-detail {
    margin-left: 72px;
    &:first-child {
      margin-bottom: 16px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .checkbox-container {
      min-height: 30px;
      display: inline-flex;
    }
    .radio-container {
      display: inline-flex;
    }
  }

  .security-option {
    margin-left: 72px;
    &:first-child {
      padding-bottom: 24px;
    }
    &:not(:first-child) {
      border-top: 1px solid $abbey-gray-settings;
      padding-top: 24px;
    }
    &:only-child {
      padding: 0;
    }

    .set-pin-container:hover {
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }

    .hint-text {
      color: $gray;
      text-decoration: none;
    }
  }

  .info-icon {
    width: 19px;
    height: 19px;
    margin-left: 7px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .external-icon {
    width: 16px;
    height: 16px;
    margin-left: 8px;
  }
}

.modal .modal-container .modal-wrap.pin-info-modal .modal-body {
  padding-left: 32px;
  padding-right: 32px;
  font-size: 20px;
  color: $gray;
}

.settings-options {
  color: $white;
  width: 154px;
}

.settings-user-email {
  margin-bottom: 23px;
  color: $white;
}

.settings-option-detail a {
  color: $subscribe-link;
  text-decoration: none;
  &.text-white {
    color: $white;
  }
}

.mr-75 {
  margin-right: 75px;
}

.mr-70 {
  margin-right: 70px;
}

.text-white {
  color: $white;
}

.mb-33 {
  margin-bottom: 33px;
}

.mb-23 {
  margin-bottom: 23px;
}

.pb-23 {
  padding-bottom: 23px;
}

.pb-40 {
  padding-bottom: 40px;
}

.mt-23 {
  margin-top: 23px;
}
.mt-18 {
  margin-top: 18px;
}
.mt-33 {
  margin-top: 33px;
}

.cursor {
  cursor: pointer;
}

.d-inline-flex {
  display: inline-flex;
}

.ml-12 {
  margin-left: 12px;
}
.ml-10 {
  margin-left: 8px;
  height: 13px;
  width: 8px;
}

.d-flex {
  display: flex;
}

.union-icon {
  display: inline-flex;
  align-items: center;
}

.settings-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .settings-logout-button {
    min-width: 189px;
    height: 51px;
    border: none;
    border-radius: 8px;
    background-color: $button-color;
    font: $primary-font;
    font-size: $medium-font-binge-watch;
    color: $primary-font-color;
    cursor: pointer;
    &:hover {
      background-color: $abbey-gray-settings;
    }
  }
  .settings-label {
    margin-top: 0;
    font: $primary-font;
    color: $primary-font-color;
    font-size: $large-font-size-mhd;
  }
}

.panic-indicator {
  width: 16px;
  height: 16px;
  background-color: $mango-tango;
  border-radius: 8px;
  display: inline-block;
  margin-right: 8px;
}

.status-description {
  color: $gray;
  margin-top: 8px;
}

@media only screen and (max-width: 1366px) {
  .settings-row {
    font: $regular-font-mhd;
    &:first-child {
      padding-bottom: 20px;
    }
    &:last-child {
      padding-bottom: 30px;
    }
    input:checked ~ span {
      font: $primary-font-mhd;
    }
  }
  .settings-page {
    margin-top: 32px;
  }
}

@media only screen and (max-width: 768px) {
  .settings-page {
    width: 550px;
    margin-top: 53px;
  }
  .settings-row {
    font: $regular-font-hd;
    &:first-child {
      padding-bottom: 18px;
    }
    &:last-child {
      padding-bottom: 25px;
    }
    input:checked ~ span {
      font: $primary-font-hd;
    }
  }
}
