@import "../../variables.scss";

.imageButton {
  background-color: transparent;
  border-width: 0px;
  padding: 0px;
  cursor: pointer;
  position: relative;
  .btn-tooltip {
    position: absolute;
    width: auto;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: $mineshaft-gray;
    color: $white;
    visibility: hidden;
    opacity: 0;
    transition: all ease-in-out 0.3s;
    white-space: nowrap;
    font: $btn-tooltip;
    pointer-events: none;
    &.top {
      top: 0px;
      transform: translateX(-50%) translateY(-130%);
      left: 50%;
    }
    &.right {
      left: 0%;
    }
    &.bottom {
      bottom: 0px;
      transform: translateX(-50%) translateY(130%);
      left: 50%;
    }
  }
  &:hover .btn-tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.button-name {
  position: relative;
  height: 0;
  bottom: -30px;
  color: $primary-font-color;
  font-size: 20px;
}

@media only screen and (max-width: 1366px) {
  .button-name {
    font-size: $small-font-size;
  }
}
