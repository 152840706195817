@import "../../variables.scss";

.rating-wrapper {
  display: inline-flex;
  align-items: center;
  .cinoche-text {
    font-weight: 600;
    margin-right: 4px;
    color: $light-orange;
    font-size: 20px;
    line-height: 27px;
    margin-left: 4px;
  }
  .rt-text {
    color: $white;
    margin-left: 6px;
    line-height: 28px;
    margin-right: 24px;
  }
  .header {
    margin-right: 24px;
  }
}
