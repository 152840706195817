@import "../../variables.scss";

.profile-menu-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 32px;
  position: relative;

  .profile-menu-avatar {
    width: 46px;
    height: 46px;

    &:hover,
    &.active {
      transform: none;

      .avatar,
      .avatar-placeholder::after {
        border: 2px solid $white;
        padding: 2px;
        margin: -4px;
      }

      .avatar-placeholder::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .avatar,
    .avatar-placeholder,
    .avatar-placeholder::after {
      width: 100%;
      height: 100%;
    }

    .avatar-placeholder {
      font-size: $small-font-size;

      span {
        padding: 0;
      }
    }
  }
}

.profile-menu-dropdown {
  position: absolute;
  top: calc(#{$top-nav-height} + 19px);
  right: 20px;
  min-width: 200px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.38);

  &.kids {
    background-color: $dark-purple;

    &::before {
      border-bottom-color: $dark-purple;
    }
  }

  // Triangle pointing toward top nav profile icon
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -11px;
    right: 24px;
    border-bottom: 15px solid $mineshaft-gray;
    border-right: 11px solid transparent;
    border-left: 11px solid transparent;
    z-index: 15;
  }

  .profile-menu-item-container {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .switch-profile-button,
  .logout-button {
    display: flex;
    align-items: center;
    padding: 7px 16px;
    background: none;
    border: 0;
    color: $white;
    cursor: pointer;
    font: $regular-font;
    font-size: $small-font-size;
    line-height: normal;
    text-align: left;

    &:hover {
      background-color: $translucent-white;
    }

    .avatar,
    .avatar-placeholder {
      width: 32px;
      height: 32px;
    }
    .avatar-placeholder {
      border-radius: 50%;
      background-color: $schedule-bg;
    }
    .profile-text {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      line-height: 19px;

      .profile-name.overflow {
        max-width: 16ch;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .profile-type {
        font-size: $small-font-size-hd;
        color: $gray;
        line-height: 16px;
      }
    }
  }

  .profile-menu-link {
    display: flex;
    align-items: center;
    padding: 14px 24px;
    font-size: $small-font-size;
    color: $white;
    text-decoration: none;

    &:hover {
      background-color: $translucent-white;
    }

    img {
      width: 14px;
      height: 14px;
    }

    span {
      margin-left: 17px;
    }
  }

  .logout-button {
    display: flex;
    padding: 14px 24px;

    img {
      width: 15px;
      height: 14px;
    }

    span {
      margin-left: 16px;
    }
  }

  .divider {
    width: 100%;
    height: 0;
    border: none;
    border-top: 0.5px solid $scorpion-gray;
    margin: 8px 0;
  }
}

@media only screen and (max-width: 1366px) {
  .profile-menu-container .profile-menu-avatar {
    width: 42px;
    height: 42px;
  }
  .profile-menu-dropdown {
    right: 18px;
  }
}
