@import "../../variables.scss";

.pin-recover-link {
  display: inline-flex;
  align-items: center;
  color: $subscribe-link;
  font-size: $medium-font-size;

  .external-icon {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
}
