@import "../../variables.scss";
#root {
  .contextmenu {
    background-color: $mineshaft-gray;
    border-radius: 10px;
    animation: fadein 0.5s;
    box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
    width: fit-content;
    min-width: 200px;
    .contextmenu__item {
      padding: 15px;
      &:hover {
        background-color: $scorpion-gray;
      }
      .button-wrap {
        display: flex;
        align-items: center;
        span {
          vertical-align: middle;
          img {
            height: 16px;
          }
        }
        .icon-container {
          display: inline-block;
          width: 45px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .label-container {
          margin-left: 8px;
        }
      }
    }
  }
}
