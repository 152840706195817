@import "../../variables.scss";

.schedules-table {
  background-color: $schedules-table-bg;
  padding: 35px 45px 52px 45px;
}

.schedule-header {
  display: inherit;
  border-bottom: 1px solid #fff;
}

.schedules-table-header {
  font: $primary-font;
  font-size: $medium-font-size;
  height: 40px;
  line-height: 1.38;
  display: inline-block;
  padding: 0 56px;
  margin: 0 0 -2px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

.schedule-header div.active {
  border-bottom: 3px solid #fff;
}

.schedule-day {
  font: $regular-font;
  font-size: $medium-font-size;
  color: $secondary-table-font-color;
  margin-top: 40px;
}

.onDemand-list p {
  font: $regular-font;
  font-size: $medium-font-size;
  color: $gray;
  margin: 24px 0;
}

.onDemand {
  margin: 12px -12px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    list-style: none;
    width: 25%;
    padding: 12px;
    box-sizing: border-box;
  }
}

.schedules {
  margin: 12px -12px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    // border-bottom: solid 1px $schedules-item-outline;
    list-style: none;
    width: 25%;
    padding: 12px;
    box-sizing: border-box;

    .schedule-wrap {
      padding: 20px 24px;
      background: $schedule-bg;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 8px;
      transition: transform 0.2s;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      &.live-card {
        position: relative;

        .progress-wrapper {
          position: absolute;
          height: 4px;
          bottom: 0;
          left: 0;
          width: 100%;
          background: $translucent-white;

          span {
            display: block;
            height: 100%;
            background: $subscribe-link;
          }
        }
      }

      & .action-wrapper:hover {
        cursor: pointer;

        button:not(.unsubscribed-icon) {
          background-color: $translucent-gray;
        }
      }

      div {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .channel-logo {
        padding-right: 10px;
      }

      .icon-wrapper {
        margin-left: 10px;
        display: flex;
        gap: 5px;
      }

      .air-info {
        font-weight: bold;
        font-size: $medium-font-size;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        line-height: 34px;
        height: 34px;
        display: flex;
        align-items: center;
      }

      .channel-info {
        color: $secondary-table-font-color;
        font-size: $medium-font-size;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: flex;
        justify-content: flex-start;
        min-width: 0;
        flex-direction: row;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        line-height: 34px;
        height: 34px;
        .ooh-icon {
          position: absolute;
          right: 0;
        }
      }

      span + span {
        margin-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
      }

      div.channel-logo {
        min-width: 76px;
        width: 76px;
        cursor: inherit;
      }

      div.action-wrapper {
        margin-left: auto;
        min-width: 45px;
        text-align: center;
        overflow: visible;

        div {
          overflow: visible;
        }
      }

      .live-icon {
        width: 45px;
        height: 45px;
        background-color: $translucent-white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          margin-left: 4px;
        }
      }

      .unsubscribed-icon {
        cursor: inherit;
      }
    }

    .recording-wrapper {
      width: 40px;
      position: relative;
    }

    .restart-icon-schedule {
      margin: 5px;
      width: 17px;
    }

    .record-icon {
      width: 40px;
      height: 40px;
      margin: 0;
      padding: 10.8px;
      background-color: $translucent-white;
      border-radius: 50%;

      img {
        width: 18.5px;
        height: 18.5px;
      }

      &:hover {
        cursor: pointer;

        button {
          background-color: $translucent-gray;
        }
      }
    }

    .record-icon-loading {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 0;
      animation: rotation 2s linear infinite;
    }
  }
}

.accordion-btn-wrapper {
  display: block;
  text-align: center;
}

.accordion {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font: $dropdown-regular-font;
  font-size: $medium-font-size;
  margin-top: 20px;
}

.icon-down {
  margin-left: 15px;

  img {
    width: 13px;
    height: 11px;
    transform: rotate(90deg);
    position: relative;
    bottom: 2px;
  }
}

.channel-info-icon-wrapper {
  display: flex;
}

.icon-up {
  margin-left: 15px;

  img {
    width: 13px;
    height: 11px;
    transform: rotate(-90deg);
    position: relative;
    bottom: 2px;
  }
}

.icon-ooh {
  padding-right: 30px;
}

@media only screen and (min-width: 1367px) and (max-width: 1919px) {
  .schedules {
    li {
      width: 33.33%;
    }
  }
  .onDemand {
    li {
      width: 33.33%;
    }
  }
}

@media only screen and (max-width: 1366px) and (min-width: 1025px) {
  .record-icon {
    margin: 9px 0 8px 10px;
  }
  .schedules {
    li {
      width: 50%;
    }
  }
  .onDemand {
    li {
      width: 50%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .schedules {
    li {
      width: 100%;
    }
  }

  .onDemand {
    li {
      width: 100%;
    }
  }
}
