@import "../../variables.scss";
.categoryListContainer {
  position: absolute;
  right: 0%;
  opacity: 1;
  overflow: auto;
  box-shadow: 0px 3px 6px $shadow-black;
  background: $mineshaft-gray 0% 0% no-repeat padding-box;
  z-index: 3;
  border-radius: 6px;
  top: 83px;
  width: 315px;
  max-height: 470px;
  padding: 24px 0;
  .list-label {
    text-transform: capitalize;
  }
}

@media only screen and (max-width: 1366px) {
  .categoryListContainer {
    width: 296px;
    max-height: 436px;
  }
}

@media only screen and (max-width: 768px) {
  .categoryListContainer {
    width: 240px;
    max-height: 345px;
  }
}
