@import "../../variables.scss";

.content-wrapper {
  position: relative;
  z-index: 8;
}

.home {
  overflow: hidden;
  padding-bottom: 34px;
  box-sizing: border-box;
  position: relative;

  &.kids {
    min-height: calc(100vh - #{$top-nav-height});
    padding-top: 22vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;

    &::before,
    &::after {
      content: "";
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      background-attachment: fixed;
    }
    &::before {
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 100%);
    }
    &::after {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  .billboard-wrapper {
    .content-wrapper {
      padding-top: 0;
    }
  }
}

.feed-page-header {
  width: 100%;
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 82px;
  z-index: 10;
  background-color: black;
  height: 113px;
  display: flex;
  padding-left: 48px;
  gap: 16px;
  align-items: center;
  box-sizing: border-box;

  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3)) drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));

  .viewAllBack {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 112px;
    }
  }
}

.no-result-found {
  text-align: center;
  font: $regular-font;
}

.swimlane-row {
  position: relative;
  z-index: 8;
}

.swimlanes {
  margin-bottom: 34px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.swimlanes::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.swimlanes {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.billboard-wrapper {
  .billboard-content {
    min-height: 500px;
    position: relative;
    box-sizing: border-box;
    padding-top: 50px;
    .content {
      margin-top: 0px;
    }
  }
}
