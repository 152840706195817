@import "../../variables.scss";

.header {
  display: inline-flex;
  align-items: center;

  .title:hover .title-tooltip {
    visibility: visible;
    opacity: 1;
  }

  .title:hover + .subtitle {
    color: $white;
    margin: 6px 0 5px 12px;
  }

  .title:hover span {
    transition: all ease-in-out 0.3s;
    padding: 0 12px 0 0;
  }

  .subtitle {
    color: $light-gray;
    font: $regular-font;
    font-size: 20px;
    line-height: 1.35;
    margin: 6px 0 5px 8px;
    @media only screen and (max-width: 1366px) {
      font-size: $medium-font-swimlane;
    }
    @media only screen and (max-width: 768px) {
      font-size: $small-font-swimlane;
    }
  }
}
.fav-channel-head {
  width: 100%;
  justify-content: space-between;
}

.title span {
  font: $primary-font;
  font-size: 28px;
  color: $white;
  line-height: 34px;
  padding: 0 8px 0 0;
  @media only screen and (max-width: 1366px) {
    font-size: $medium-font-swimlane;
    line-height: 24px;
  }
  @media only screen and (max-width: 768px) {
    font-size: $small-font-swimlane;
    line-height: 20px;
  }
}

.title-tooltip {
  position: absolute;
  width: auto;
  color: $white;
  visibility: hidden;
  opacity: 0;
  transition: all ease-in-out 0.3s;
  white-space: nowrap;
  font: $btn-tooltip !important;
  pointer-events: none;
  margin: 7px 0 0 53px;
}

.edit-favourite-channel-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 32px;
  font-size: 20px;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
  &:hover {
    background-color: $modal-accent;
  }
  @media only screen and (max-width: 1366px) {
    font-size: 18px;
  }
  .edit-fav-channel-icon {
    margin-right: 10px;
    margin-top: 5px;
    img {
      width: 20px;
      height: 20px;
    }
  }
}

.title a {
  text-decoration: none;
  & button {
    margin-left: 10px;
    transition: all ease-in-out 0.2s;
    & img {
      height: 19px;
    }
    @media only screen and (max-width: 1366px) {
      margin-left: 8px;
      & img {
        height: 12px;
      }
    }
    @media only screen and (max-width: 768px) {
      margin-left: 7px;
      & img {
        height: 11px;
      }
    }
  }
}
